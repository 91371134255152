import React, { useReducer, createContext } from 'react';
import { useReducerAsync } from 'use-reducer-async';
import OrganisationReducer from '../reducers/OrganisationReducer';
import Organisation from '../Models/Organisation';

function OrganisationContext(reducer, initialState) {
	const Context = createContext(initialState);

	const Provider = ({ children }) => {
		const [organisationState, organisationDispatch] = useReducer(reducer, initialState);

		return (
			<Context.Provider value={{ organisationState, organisationDispatch }}>
				{children}
			</Context.Provider>
		);
	};

	return { Context, Provider };
}

export const { Context, Provider } = OrganisationContext(OrganisationReducer, {
	organisation: new Organisation(),
});
