import DashboardNotification from '../Models/DashboardNotification';
import AuthService from './AuthService'

const NotificationService = {
	getNotifications: async function (type: string = 'global', page: number = 1, perPage: number = 10) {
		const params = {
			type: type,
			page: page,
			per_page: perPage,
		}
		const resp = await AuthService.get(`notifications`, params);
		let data = resp.data.data;

		const notifications: Array<DashboardNotification> = [];
		for (let json of data.data) {
			const notification = DashboardNotification.fromJSON(json);
			notifications.push(notification)
		}
		data['data'] = notifications;
		return data;
	},
	getNotificationChannels: async function () {
		const resp = await AuthService.get(`notifications/channels`);
		return resp.data.data;
	}
}

export default NotificationService;