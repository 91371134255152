import React, { useState, useContext, useEffect } from "react"
import { Context as UserContext } from '../../context/UserContext';

import clsx from "clsx"

import { Collapse } from "reactstrap"

import PerfectScrollbar from "react-perfect-scrollbar"
import { connect } from "react-redux"

import { NavLink } from "react-router-dom"
import { setSidebarToggleMobile } from "../../reducers/ThemeOptions"

import SidebarUserbox from "../SidebarUserbox"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	Copy,
	ChevronRight,
	Briefcase,
	BookOpen,
	Video,
	PlusSquare,
	Archive,
	User,
	Bell,
} from "react-feather";
import { useNavigate } from 'react-router-dom';

function useForceUpdate() {
	const [value, setValue] = useState(0); // integer state
	return () => setValue(value => value + 1); // update the state to force render
}
const SidebarMenu = (props) => {
	const { userState, userDispatch } = useContext(UserContext);
	let navigate = useNavigate();

	const { setSidebarToggleMobile, sidebarUserbox } = props

	const [activeTab, setActiveTab] = useState<string>('dashboard');

	const toggleSidebarMobile = () => setSidebarToggleMobile(false);
	const forceUpdate = useForceUpdate();

	const globalSettingsParams = [
		'oauth',
		'media',
		'theolive',
		'mails',
		'translations',
		'faq',
		'messageShortcuts',
		'mollie',
		'datasources',
	]

	const calculateActiveTab = () => {
		const path = window.location.pathname;
		const param = path.split('/')[1];
		const active = globalSettingsParams.includes(param) ? 'global-settings' : param;
		setActiveTab(active);
		forceUpdate();
	}

	function getFirstParam() {
		const path = window.location.pathname;
		const param = path.split('/')[1];
		return param;
	}

	useEffect(() => {
		calculateActiveTab();
	}, [window.location.pathname])

	return (
		<>
			<style>
				{`
				.menu-item::after { display: none;}
				.custom-menu-item:hover { 
					cursor: pointer;
				}
			`}
			</style>
			<div className="sidebar-navigation">
				<PerfectScrollbar>
					<div className="sidebar-header">
						<span>Navigation menu</span>
					</div>
					<ul>
						<li>
							<a
								onClick={() => {
									setActiveTab('dashboard')
									navigate('/dashboard')
								}}
								className="nav-link-simple"
							>
								<span className="sidebar-icon">
									<BookOpen />
								</span>
								<span className="sidebar-item-label">
									Dashboard
								</span>
							</a>
						</li>
						<li>
							<a
								onClick={() => {
									if (getFirstParam() != 'events') {
										navigate('/events');
									}
									setActiveTab(activeTab == 'events' ? '' : 'events');
								}}
								className={clsx({ active: activeTab == 'events' })}
							>
								<span className="sidebar-icon">
									<Video />
								</span>
								<span className="sidebar-item-label">
									Events
								</span>
								{userState.user.hasRole('Organisation Manager') && (
									<span className="sidebar-icon-indicator">
										<ChevronRight />
									</span>
								)}
							</a>
							{userState.user.hasRole('Organisation Manager') && (
								<Collapse isOpen={activeTab == 'events'}>
									<ul>
										<li>
											<NavLink
												to="/events/create"
												onClick={toggleSidebarMobile}
												className="menu-item"
											>
												<span className="sidebar-icon">
													<PlusSquare />
												</span>
												<span className="sidebar-item-label">
													Create Event
												</span>
											</NavLink>
										</li>

										<li>
											<NavLink
												to="/events"
												onClick={toggleSidebarMobile}
												end
												className="menu-item"
											>
												<span className="sidebar-icon">
													<Archive />
												</span>
												<span className="sidebar-item-label">
													Overview
												</span>
											</NavLink>
										</li>
									</ul>
								</Collapse>
							)}
						</li>
						<li>
							<NavLink
								to="/templates"
								onClick={() => setActiveTab('templates')}
							>
								<span className="sidebar-icon">
									<Copy />
								</span>
								<span className="sidebar-item-label">
									Templates
								</span>
							</NavLink>
						</li>
						<li>
							<a
								onClick={() => {
									if (getFirstParam() != 'organisations') {
										navigate('/organisations');
									}
									setActiveTab(activeTab == 'organisations' ? '' : 'organisations');
								}}
								className={clsx({ active: activeTab == 'organisations' })}
							>
								<span className="sidebar-icon">
									<Briefcase />
								</span>
								<span className="sidebar-item-label">
									Organisations
								</span>
								{userState.user.hasRole('Project Manager') && (
									<span className="sidebar-icon-indicator">
										<ChevronRight />
									</span>
								)}
							</a>
							{userState.user.hasRole('Project Manager') && (
								<Collapse isOpen={activeTab == 'organisations'}>
									<ul>
										<li>
											<NavLink
												to="/organisations/create"
												onClick={toggleSidebarMobile}
												className="menu-item"
											>
												<span className="sidebar-icon">
													<PlusSquare />
												</span>
												<span className="sidebar-item-label">
													Create Organisation
												</span>
											</NavLink>
										</li>
										<li>
											<NavLink
												to="/organisations"
												onClick={toggleSidebarMobile}
												end
												className="menu-item"
											>
												<span className="sidebar-icon">
													<Archive />
												</span>
												<span className="sidebar-item-label">
													Overview
												</span>
											</NavLink>
										</li>
									</ul>
								</Collapse>
							)}
						</li>

						{userState.user.hasRole('Admin') && (
							<li>
								<a
									onClick={() => {
										if (getFirstParam() != 'users') {
											navigate('/users');
										}
										setActiveTab(activeTab == 'users' ? '' : 'users');
									}}
									className={clsx({ active: activeTab == 'users' })}
								>
									<span className="sidebar-icon">
										<User />
									</span>
									<span className="sidebar-item-label">
										Dashboard users
									</span>
									<span className="sidebar-icon-indicator">
										<ChevronRight />
									</span>
								</a>
								<Collapse isOpen={activeTab == 'users'}>
									<ul>
										<li>
											<NavLink
												to="/users/create"
												onClick={toggleSidebarMobile}
												className="menu-item"
											>
												<span className="sidebar-icon">
													<PlusSquare />
												</span>
												<span className="sidebar-item-label">
													Create elevated user
												</span>
											</NavLink>
										</li>
										<li>
											<NavLink
												onClick={toggleSidebarMobile}
												to="/users"
												end
												className="menu-item"
											>
												<span className="sidebar-icon">
													<Archive />
												</span>
												<span className="sidebar-item-label">
													Overview
												</span>
											</NavLink>
										</li>
									</ul>
								</Collapse>
							</li>
						)}

						{userState.user.hasRole('Admin') && (
							<li>
								<a
									onClick={() => {
										setActiveTab(activeTab == 'global-settings' ? '' : 'global-settings');
									}}
									className={clsx({ active: activeTab == 'global-settings' })}
								>
									<span className="sidebar-icon">
										<FontAwesomeIcon icon={['fas', 'toolbox']} className="font-size-lg" />
									</span>
									<span className="sidebar-item-label">
										Global settings
									</span>
									<span className="sidebar-icon-indicator">
										<ChevronRight />
									</span>
								</a>
								<Collapse isOpen={activeTab == 'global-settings'}>
									<ul>
										<li>
											<NavLink
												to="/oauth"
												onClick={toggleSidebarMobile}
												className="menu-item"
											>
												<span className="sidebar-icon">
													<FontAwesomeIcon icon={['fas', 'user-shield']} className="font-size-lg" />
												</span>
												<span className="sidebar-item-label">
													OAuth Credentials
												</span>
											</NavLink>
										</li>
										<li>
											<NavLink
												to="/media"
												onClick={toggleSidebarMobile}
												className="menu-item"
											>
												<span className="sidebar-icon">
													<FontAwesomeIcon icon={['fas', 'photo-video']} className="font-size-lg" />
												</span>
												<span className="sidebar-item-label">
													Media library
												</span>
											</NavLink>
										</li>
										<li>
											<NavLink
												to="/theolive"
												onClick={toggleSidebarMobile}
												className="menu-item">
												<span className="sidebar-icon">
													<FontAwesomeIcon icon={['fas', 'play-circle']} className="font-size-lg" />
												</span>
												<span className="sidebar-item-label">
													TheoLive
												</span>
											</NavLink>
										</li>
										<li>
											<NavLink
												to="/mails"
												onClick={toggleSidebarMobile}
												className="menu-item"
											>
												<span className="sidebar-icon">
													<FontAwesomeIcon icon={['fas', 'envelope']} className="font-size-lg" />
												</span>
												<span className="sidebar-item-label">
													Mails
												</span>
											</NavLink>
										</li>
										<li>
											<NavLink
												to="/translations"
												onClick={toggleSidebarMobile}
												className="menu-item"
											>
												<span className="sidebar-icon">
													<FontAwesomeIcon icon={['fas', 'language']} className="font-size-lg" />
												</span>
												<span className="sidebar-item-label">
													Translations
												</span>
											</NavLink>
										</li>
										<li>
											<NavLink
												to="/faq"
												onClick={toggleSidebarMobile}
												className="menu-item"
											>
												<span className="sidebar-icon">
													<FontAwesomeIcon icon={['fas', 'question']} className="font-size-lg" />
												</span>
												<span className="sidebar-item-label">
													Global FAQs
												</span>
											</NavLink>
										</li>
										<li>
											<NavLink
												to="/messageShortcuts"
												onClick={toggleSidebarMobile}
												className="menu-item"
											>
												<span className="sidebar-icon">
													<FontAwesomeIcon icon={['fas', 'bolt']} className="font-size-lg" />
												</span>
												<span className="sidebar-item-label">
													Message shortcuts
												</span>
											</NavLink>
										</li>
										<li>
											<NavLink
												to="/mollie"
												onClick={toggleSidebarMobile}
												className="menu-item"
											>
												<span className="sidebar-icon">
													<FontAwesomeIcon icon={['fas', 'money-bill']} className="font-size-lg" />
												</span>
												<span className="sidebar-item-label">
													Mollie payments
												</span>
											</NavLink>
										</li>
										<li>
											<NavLink
												onClick={toggleSidebarMobile}
												to="/datasources"
												className="menu-item"
											>
												<span className="sidebar-icon">
													<FontAwesomeIcon icon={['fas', 'database']} className="font-size-lg" />
												</span>
												<span className="sidebar-item-label">
													Datasources
												</span>
											</NavLink>
										</li>
									</ul>
								</Collapse>
							</li>
						)}

						{userState.user.hasRole('Admin') && (
							<li>
								<NavLink
									to="/activity"
									onClick={() => setActiveTab('activity')}
								>
									<span className="sidebar-icon">
										<FontAwesomeIcon icon={['fas', 'list-ul']} className="font-size-lg" />
									</span>
									<span className="sidebar-item-label">
										Activity
									</span>
								</NavLink>
							</li>
						)}
						<li>
							<NavLink
								to="/notifications"
								onClick={() => setActiveTab('notifications')}
								className="nav-link-simple"
							>
								<span className="sidebar-icon">
									<Bell />
								</span>
								<span className="sidebar-item-label">
									Notifications
								</span>
							</NavLink>
						</li>
					</ul>
				</PerfectScrollbar>
			</div>
		</>
	)
}

const mapStateToProps = (state) => ({
	sidebarUserbox: state.ThemeOptions.sidebarUserbox,
	sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
})

const mapDispatchToProps = (dispatch) => ({
	setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
