import React, { useState, useContext, useEffect } from "react"

import { Bell, Activity, Calendar } from 'react-feather';

import { Collapse, Badge, Button } from "reactstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AuthService from "../../Services/AuthService";

import { useNavigate } from 'react-router-dom';

const SidebarFooter = () => {
	const navigate = useNavigate();

	const [araAdminVersion, setAraAdminVersion] = useState('');
	const [araFrontendVersion, setAraFrontendVersion] = useState('');

	const initVersions = async () => {
		const response = await AuthService.versions();
		setAraAdminVersion(response.data.ara_admin_version);
		setAraFrontendVersion(response.data.ara_frontend_version);
	}

	useEffect(() => {
		initVersions();
	}, [])

  return (
    <>
		<div className="app-sidebar--footer">
			<div className="text-center">
				<h6 className="text-center mb-2" style={{ width: '100%', color: 'white' }}>Admin version {araAdminVersion}</h6>
				<h6 className="text-center mb-2" style={{ width: '100%', color: 'white' }}>Frontend version {araFrontendVersion}</h6>
				<Button
					color="warning"
					className="m-1 py-1 px-3 d-inline-block shadow-none text-center font-size-lg rounded"
					onClick={() => {
						localStorage.removeItem('_myAraToken');
						navigate('/login')
					}}
					id="CollapsedSidebarTooltip131"
				>
					<FontAwesomeIcon icon={['fas', 'sign-out-alt']} className="mr-2" />
					Log out
				</Button>
			</div>
		</div>
    </>
  );
};

export default SidebarFooter;
