import React from 'react';

import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UncontrolledTooltip } from 'reactstrap';

import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import {
  setSidebarToggle,
  setSidebarToggleMobile
} from '../../reducers/ThemeOptions';

import projectLogo from '../../assets/images/logo-trans.png';

const SidebarHeader = (props) => {
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const {
    sidebarToggleMobile,
    setSidebarToggleMobile,

    sidebarToggle,
    setSidebarToggle
  } = props;

  return (
    <>
      <div className="app-sidebar--header">
        <div className="app-sidebar-logo">
          <NavLink
            to="/dashboard"
            title=""
            className="app-sidebar-logo">
            <div className="app-sidebar-logo--icon">
              {/* <img
                alt="logo"
                src={projectLogo}
              /> */}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 359.27 281.29"><path d="M303.74 30.17 267.3 131.43 222.49 4.04C189.88-.73 160.87-.79 138.8 1.35L91.63 131.43 55.29 27.29c-71.34 44.5-70.98 133.31-14.24 189.52l-8.77 23.39-15.41 41.09 23.36-19.03 28.79-23.46c19.11 11.9 41.95 20.78 68.27 25.24l41.24-110.19 39.58 109.27C349.55 236.98 412.5 93.61 303.73 30.17" style={{ fill: "white"}}/></svg>
            </div>
            <div className="app-sidebar-logo--text" style={{textTransform: "none"}}>
              <b>Webble-Up</b>
            </div>
          </NavLink>
        </div>
        <button
          onClick={toggleSidebar}
          className="btn btn-sm collapse-sidebar-btn"
          id="CollapseSidebarTooltip">
          <FontAwesomeIcon icon={['far', 'dot-circle']} size="lg" />
        </button>
        <UncontrolledTooltip
          target="CollapseSidebarTooltip"
          container=".app-sidebar"
          placement="right">
          Collapse sidebar
        </UncontrolledTooltip>
        <button
          className={clsx(
            'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
            { 'is-active': sidebarToggleMobile }
          )}
          onClick={toggleSidebarMobile}>
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
        <button
          onClick={toggleSidebar}
          className="expand-sidebar-btn btn btn-sm"
          id="ExpandSidebarTooltip">
          <FontAwesomeIcon icon={['fas', 'arrows-alt-h']} />
        </button>
        <UncontrolledTooltip
          target="ExpandSidebarTooltip"
          container=".app-sidebar"
          placement="right">
          Expand sidebar
        </UncontrolledTooltip>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggle: (enable) => dispatch(setSidebarToggle(enable)),
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarHeader);
