import React, {useReducer, createContext} from 'react';
import { useReducerAsync } from 'use-reducer-async';

export default function DataContext(reducer, initialState, asyncActionHandlers) {	
	const Context = createContext(initialState)

	const Provider = ({children}) => {
		//const [state, dispatch] = useReducer(reducer, initialState)
		const [state, dispatch] = useReducerAsync(reducer, initialState, asyncActionHandlers)
	

		return (
			<Context.Provider value={{state, dispatch}}>
				{children}
			</Context.Provider>
		)
	};

	return {Context, Provider}
}
