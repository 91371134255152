export default class Organisation {
	private id: number | null
	private name: string
	private slug: string
	private logo: string
	private email: string
	private color: string
	private favicon: string
	private user_roles: string[]

	constructor(
		id: number | null = null,
		name: string = "",
		slug: string = "",
		logo: string = "",
		color: string = "",
		favicon: string = "",
		email: string = "",
		user_roles: string[] = []
	) {
		this.id = id
		this.name = name
		this.slug = slug
		this.logo = logo
		this.color = color
		this.favicon = favicon
		this.email = email
		this.user_roles = user_roles;
	}
	get $id() {
		return this.id
	}

	set $name(name: string) {
		this.name = name
	}

	get $name(): string {
		return this.name
	}

	set $slug(slug: string) {
		this.slug = slug
	}

	get $slug() {
		return this.slug
	}

	set $logo(logo: string) {
		this.logo = logo
	}

	get $logo() {
		return this.logo
	}

	set $favicon(favicon: string) {
		this.favicon = favicon
	}

	get $favicon() {
		return this.favicon
	}

	set $color(color: string) {
		this.color = color
	}

	get $color() {
		return this.color
	}

	set $email(email: string) {
		this.email = email
	}

	get $email() {
		return this.email
	}

	public static fromJSON(data: any): Organisation {
		var organisation = new Organisation(
			data.id,
			data.name,
			data.slug,
			data.logo,
			data.color,
			data.favicon,
			data.email,
			data.user_roles
		)
		return organisation
	}
	public hasUserRole(value: string, recursive: boolean = true) {
		if (!this.user_roles) return false;

		if (recursive) {
			return this.user_roles.includes(value);
		} else {
			return this.user_roles[0] == value;
		}
	}
}
