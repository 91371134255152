import { v4 as uuid } from "uuid";
import MetaTag from "./MetaTag";
import User from "./User";
export default class Speaker {
	private id: number | null;
	private title: string | null;
	private slug: string | null;
	private event_id: number | null;
	private user: User | null;
	private description: string | null;
	private key: string | null;
	private thumbnail: string | null;
	private layout: any | null;
	private first_name: string;
	private last_name: string;

	private socials: any | null;
	private company: string | null;
	private metaTag: MetaTag | null;

	constructor(
		id: number | null = null,
		title: string | null = null,
		slug: string | null = null,
		event_id: number | null = null,
		user: User | null = null,
		description: string | null = null,
		thumbnail: string | null = null,
		layout: any | null = null,
		first_name: string = '',
		last_name: string = '',
		socials: any | null = null,
		company: string | null = null,
		metaTag: MetaTag | null = null,
	) {
		this.key = uuid();
		this.id = id;
		this.title = title;
		this.slug = slug;
		this.event_id = event_id;
		this.user = user;
		this.description = description;
		this.thumbnail = thumbnail;
		this.layout = layout;
		this.first_name = first_name;
		this.last_name = last_name;
		this.socials = socials;
		this.company = company;
		this.metaTag = metaTag;
	}

	public static fromJSON(json: any): Speaker {
		var speaker: Speaker = new Speaker();
		Object.assign(speaker, json);
		if (json.user) {
			speaker.$user = User.fromJSON(json.user)
		}
		if (json.layout) {
			speaker.$layout = Object.assign({}, json.layout);
		}

		if (json.meta_tag) {
			speaker.$metaTag = MetaTag.fromJSON(json.meta_tag);
		}

		return speaker;
	}

	public get $key(): string | null {
		return this.key;
	}
	public get $id(): number | null {
		return this.id;
	}
	public get $title(): string | null {
		return this.title;
	}
	public get $slug(): string | null {
		return this.slug;
	}
	public get $event_id(): number | null {
		return this.event_id;
	}
	public get $user(): User | null {
		return this.user;
	}
	public get $description(): string | null {
		return this.description;
	}
	public get $thumbnail(): string | null {
		return this.thumbnail;
	}
	public get $layout(): any | null {
		return this.layout;
	}
	public get $name(): string {
		let name = this.first_name;
		if (this.first_name && this.last_name) {
			name += ' ' + this.last_name;
		} else {
			name = this.last_name;
		}
		return name;
	}
	public get $first_name(): string {
		return this.first_name;
	}
	public get $last_name(): string {
		return this.last_name;
	}
	public get $socials(): any | null {
		return this.socials;
	}
	public get $company(): string | null {
		return this.company;
	}

	public set $id(id: number | null) {
		this.id = id;
	}
	public set $title(title: string | null) {
		this.title = title;
	}
	public set $slug(slug: string | null) {
		this.slug = slug;
	}
	public set $event_id(event_id: number | null) {
		this.event_id = event_id;
	}
	public set $user(value: User | null) {
		this.user = value;
	}
	public set $description(description: string | null) {
		this.description = description;
	}
	public set $thumbnail(value: string | null) {
		this.thumbnail = value;
	}
	public set $layout(value: any | null) {
		this.layout = value;
	}
	public set $first_name(value: string | null) {
		this.first_name = value;
	}
	public set $last_name(value: string | null) {
		this.last_name = value;
	}
	public set $socials(value: any | null) {
		this.socials = value;
	}
	public set $company(value: string | null) {
		this.company = value;
	}
	public get $metaTag(): MetaTag | null {
		return this.metaTag;
	}
	public set $metaTag(value: MetaTag | null) {
		this.metaTag = value;
	}
}