import React, { Reducer } from "react"
import Event from "../Models/Event"
import EventBlock from "../Models/EventBlock"
import EventService from "../Services/EventService"
import { useReducerAsync, AsyncActionHandlers } from "use-reducer-async"

const loadEvent = async (id: number, languageSlug: string = undefined) => {
	try {
		let event = await EventService.get(id)

		return event
	} catch (err) {
		const response = err.response.data
		throw new Error(response.code)
	}
}

type State = {
	event: Event
	loading: boolean
	block_loading: boolean
}

type Action =
	| { type: "START_FETCH" }
	| { type: "END_FETCH"; event: Event }
	| { type: "UPDATE_BLOCKS"; blocks: Array<EventBlock>; zone_index: number }
	| { type: "ERROR_FETCH"; code: String }

type AsyncAction = { type: "FETCH"; id: number; language_slug: string; callback: Function }

const asyncActionHandlers: AsyncActionHandlers<
	Reducer<State, Action>,
	AsyncAction
> = {
	FETCH: ({ dispatch }) => async (action) => {
		dispatch({ type: "START_FETCH" })
		try {
			const event = await loadEvent(action.id, action.language_slug)
			action.callback()
			dispatch({ type: "END_FETCH", event: event })
		} catch (e) {
			dispatch({ type: "ERROR_FETCH", code: e.message })
		}
	},
}

const EventReducer = (state, action) => {
	switch (action.type) {
		case "START_FETCH":
			return {
				...state,
				loading: true,
			}
		case "END_FETCH":


			return {
				...state,
				loading: false,
				event: action.event,
			}
		case "ERROR_FETCH":
			return {
				...state,
				event: null,
			}
		case "UPDATE_BLOCKS":


			state.event.$zones.map(function (z, i) {
				if (z.$id == action.zone_index) {
					z.$blocks = action.blocks
				}
			})
			return {
				...state,
				loading: false,
				event: state.event,
			}
		default:

			return { ...state }
	}
}

export default EventReducer
export { asyncActionHandlers }
