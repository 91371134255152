import Event from "./Event";
import Organisation from "./Organisation";
import User from "./User";

export default class DashboardNotification {
	private id: number | null;
	private type: string;
	private message: string;
	private subject: any;
	private subjectType: string;
	private related: any;
	private relatedType: string;
	private causer: any;
	private causerType: string;
	private properties: Object;
	private date: Date;

	constructor(
		id: number | null = null,
		type: string = '',
		message: string = '',
		subject: any = null,
		subjectType: string = '',
		related: any = null,
		relatedType: string = '',
		causer: any = null,
		causerType: string = '',
		properties: Object = {},
		date: Date = new Date(),
	) {
		this.id = id;
		this.type = type;
		this.message = message;
		this.subject = subject;
		this.subjectType = subjectType;
		this.related = related;
		this.relatedType = relatedType;
		this.causer = causer;
		this.causerType = causerType;
		this.properties = properties;
		this.date = date;
	}

	public static fromJSON(json: any): DashboardNotification {
		let dashboardNotification: DashboardNotification = new DashboardNotification();
		Object.assign(dashboardNotification, json);

		if (json.hasOwnProperty('properties')) {
			dashboardNotification.properties = JSON.parse(json.properties);
		}

		if (json.subject_id) {
			const [type, model] = this.getMorphModel(json, 'subject');

			if (!model) {
				dashboardNotification.properties.subject = `${type} has been deleted`
			} else {
				dashboardNotification.subject = model;
				dashboardNotification.subjectType = type;
			}
		}

		if (json.related_id) {
			const [type, model] = this.getMorphModel(json, 'related');

			if (!model) {
				dashboardNotification.properties.related = `${type} has been deleted`
			} else {
				dashboardNotification.related = model;
				dashboardNotification.relatedType = type;
			}
		}

		if (json.causer_id) {
			const [type, model] = this.getMorphModel(json, 'causer');

			if (!model) {
				dashboardNotification.properties.causer = `${type} has been deleted`
			} else {
				dashboardNotification.causer = model;
				dashboardNotification.causerType = type;
			}
		}

		if (json.created_at) {
			dashboardNotification.$date = new Date(json.created_at);
		}

		return dashboardNotification;
	}

	private static getMorphModel(json: any, model: any) {
		const splitSubjectType = json[model + '_type'].split('\\');
		const subjectType = splitSubjectType[splitSubjectType.length - 1];

		if (!json[model]) {
			return [subjectType, null];
		}

		switch (subjectType) {
			case 'Event':
				return [subjectType, Event.fromJSON(json[model])];
			case 'Organisation':
				return [subjectType, Organisation.fromJSON(json[model])];
			case 'User':
				return [subjectType, User.fromJSON(json[model])];
			default:
				console.error("DashboardNotification subject type '" + subjectType + "' not found");
				return [subjectType, {}];
		}
	}

	/**
	 * GETTERS
	 */

	public get $id(): number | null { return this.id; }

	public get $type(): string { return this.type; }

	public get $message(): string { return this.message; }

	public get $subject(): any { return this.subject; }

	public get $subjectType(): string { return this.subjectType; }

	public get $related(): any { return this.related; }

	public get $relatedType(): string { return this.relatedType; }

	public get $causer(): any { return this.causer; }

	public get $causerType(): string { return this.causerType; }

	public get $properties(): Object { return this.properties; }

	public get $date(): Date { return this.date; }


	/**
	 * SETTERS
	 */

	public set $id(value: number | null) { this.id = value; }

	public set $type(value: string) { this.type = value; }

	public set $message(value: string) { this.message = value; }

	public set $subject(value: any) { this.subject = value; }

	public set $subjectType(value: string) { this.subjectType = value; }

	public set $related(value: any) { this.related = value; }

	public set $relatedType(value: string) { this.relatedType = value; }

	public set $causer(value: any) { this.causer = value; }

	public set $causerType(value: any) { this.causerType = value; }

	public set $properties(value: Object) { this.properties = value; }

	public set $date(value: Date) { this.date = value; }
}