import React, { FC, useState, useEffect, useContext } from 'react';
import { withSocket } from '../../HOC/Socket/withSocket';
import NotificationService from '../../Services/NotificationService';
import { toast, Bounce } from 'react-toastify';
import { Context as UserContext } from '../../context/UserContext';

interface NotificationsProps {
}

const Notifications: FC<NotificationsProps> = (props: NotificationsProps) => {
	const { userState, dispatch } = useContext(UserContext);

	function showNotification(mqttMessage) {
		const json = JSON.parse(mqttMessage.message);
		switch (json.type) {
			case 'success':
				toast.success(json.message, {
					containerId: "B",
					transition: Bounce,
				});
				break;
			case 'error':
				toast.error(json.message, {
					containerId: "B",
					transition: Bounce,
				});
				break;
			default:
				toast.info(json.message, {
					containerId: "B",
					transition: Bounce,
				});
		}
	}

	async function init() {
		if (!userState.user?.$id || !userState.user.hasRole("Admin")) {
			/* Added this, because otherwise an infinite loop would occur due to the api call here
			that uses the AuthService. Which would redirect to the login page, which would re-render this component
			and so on...*/
			return;
		}

		try {
			const channels = await NotificationService.getNotificationChannels();
			channels.forEach(channel => {
				props.subscribe(channel, (mqttMessage) => {
					showNotification(mqttMessage);
				});
			});
		} catch (e) {
			// console.error(e);
		}
	}

	useEffect(() => {
		init();
	}, [userState.user])

	return (
		<>
		</>
	)
}
export default withSocket(Notifications);