import { v4 as uuid } from "uuid";
import MetaTag from "./MetaTag";
import Session from './Session';


export default class EventRoom {
	private id: number | null;
	private title: string | null;
	private slug: string | null;
	private logo: string | null;
	private layout: any | null;
	private layout_settings: any | null;
	private event_id: number | null;
	private sessions: Array<Session> | null = null;
	private key: string | null;
	private flip_layout: boolean | null;
	private default_title: string | null;
	private metaTag: MetaTag | null;

	constructor(
		id: number | null = null,
		title: string | null = null,
		slug: string | null = null,
		logo: string | null = null,
		layout: any | null = null,
		layout_settings: any | null = null,
		event_id: number | null = null,
		sessions: Array<Session> = [],
		key: string | null = null,
		flip_layout: boolean | null = null,
		default_title: string | null = null,
		metaTag: MetaTag | null = null,
	) {
		this.id = id;
		this.title = title;
		this.slug = slug;
		this.logo = logo;
		this.layout = layout;
		this.layout_settings = layout_settings;
		this.event_id = event_id;
		this.sessions = sessions;
		this.key = key;
		this.flip_layout = flip_layout;
		this.default_title = default_title;
		this.metaTag = metaTag;
	}

	public static fromJSON(json: any): EventRoom {
		var eventRoom: EventRoom = new EventRoom();
		Object.assign(eventRoom, json);

		if (json.layout) {
			eventRoom.$layout = Object.assign({}, json.layout);
		}

		if (json.layout_settings && typeof json.layout_settings == 'string') {
			eventRoom.$layout_settings = JSON.parse(json.layout_settings);
		}

		if (json.sessions) {
			eventRoom.$sessions = json.sessions.map((session: any) => {
				return Session.fromJSON(session);
			});
		}

		if (json.meta_tag) {
			eventRoom.$metaTag = MetaTag.fromJSON(json.meta_tag)
		}


		return eventRoom;
	}

	public get $flip_layout(): boolean | null { return this.flip_layout; }
	public set $flip_layout(value: boolean | null) { this.flip_layout = value; }

	public get $defaultTitle(): string | null { return this.default_title; }
	public set $defaultTitle(value: string | null) { this.default_title = value; }

	public get $key(): string | null {
		if (!this.key) {
			this.key = uuid();
		}
		return this.key;
	}

	public get $id(): number | null {
		return this.id;
	}

	public get $title(): string | null {
		return this.title;
	}

	public get $slug(): string | null {
		return this.slug;
	}

	public get $logo(): string | null {
		return this.logo;
	}

	public get $layout(): any | null {
		return this.layout;
	}

	public get $layout_settings(): any | null {
		return this.layout_settings;
	}

	public get $event_id(): number | null {
		return this.event_id;
	}

	public get $metaTag(): MetaTag | null {
		return this.metaTag;
	}

	public set $sessions(value: Array<Session> | null) {
		this.sessions = value;
	}

	public get $sessions(): Array<Session> | null {
		return this.sessions;
	}

	public set $id(value: number | null) {
		this.id = value;
	}

	public set $title(value: string | null) {
		this.title = value;
	}

	public set $slug(value: string | null) {
		this.slug = value;
	}

	public set $logo(value: string | null) {
		this.logo = value;
	}

	public set $layout(value: any | null) {
		this.layout = value;
	}

	public set $layout_settings(value: any | null) {
		this.layout_settings = value;
	}

	public set $event_id(value: number | null) {
		this.event_id = value;
	}
	public set $key(value: string | null) {
		this.key = value;
	}
	public set $metaTag(value: MetaTag | null) {
		this.metaTag = value;
	}


}