import BlockTemplate from "./BlockTemplate"

export default class Block {
	private id: number | null
	private name: string
	private settings: string
	private icon: string | null
	private description: string | null
	private templates: Array<BlockTemplate>
	private moderator_react_path: string | null;
	private interactive: boolean | null;

	constructor(
		id: number | null = 0,
		name: string = "",
		settings: string = "",
		icon: string = "",
		description: string = "",
		templates: Array<BlockTemplate> = [],
		moderatorReactPath: string = "",
		interactive: boolean | null
	) {
		this.id = id;
		this.name = name;
		this.description = description;
		this.settings = settings;
		this.icon = icon;
		this.templates = templates;
		this.moderator_react_path = moderatorReactPath;
		this.interactive = interactive;
	}

	/**
	 * Create a Block object from json
	 * @param json The json representation of a user
	 * @return {Block} A block object based on the information in the provided json
	 */
	public static fromJSON(json: any): Block {
		var block: Block = new Block()
		Object.assign(block, json)

		var blockTemplates: Array<BlockTemplate> = []
		if (json.templates) {
			json.templates.forEach((template: Object) => {
				blockTemplates.push(BlockTemplate.fromJSON(template))
			})
		}

		block.$templates = blockTemplates

		return block
	}

	public get $name(): string { return this.name; }
	public get $settings(): string { return this.settings; }
	public get $icon(): string | null { return this.icon; }
	public get $description(): string | null { return this.description; }
	public get $id(): number | null { return this.id; }
	public get $moderatorPath(): string | null { return this.moderator_react_path; }
	public get $templates(): Array<BlockTemplate> { return this.templates; }
	public get $interactive(): boolean | null { return this.interactive; }

	public set $templates(templates) { this.templates = templates; }
}
