import React from "react"
import EventReducer from "../reducers/EventReducer"
import DataContext from "./DataContext"
import Event from "../Models/Event"
import { asyncActionHandlers } from "../reducers/EventReducer"

export const { Context, Provider } = DataContext(
  EventReducer,
  {
    loading: false,
    block_loading: false,
    event: new Event(),
    templateComps: {},
  },
  asyncActionHandlers
)
