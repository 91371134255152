import Organisation from '../Models/Organisation';

type Action = { type: 'SET_ORGANISATION'; organisation: Organisation };

type AsyncAction = {
	type: 'FETCH';
};

const OrgansationReducer = (state, action) => {
	switch (action.type) {
		case 'SET_ORGANISATION':
			return {
				...state,
				organisation: action.organisation,
			};
	}
};

export default OrgansationReducer;
