import Block from "./Block"
import BlockTemplate from "./BlockTemplate"
import PaymentPlan from "./PaymentPlan"

export default class EventBlock {
	private path: string
	private settings: Object
	private icon: string
	private name: string
	private description: string
	private unsavedSettings: Object
	private id: number
	private isChanged: boolean = false
	private block: Block | null
	private onDelete: any[];
	private moderatorPath: string;
	private presenterPath: string;
	private title: string;
	private visible: boolean;
	private models: Array<any>;
	private visible_in_hybrid: boolean;
	private templates: Array<BlockTemplate>;
	private block_template: BlockTemplate;
	private paymentPlans: Array<PaymentPlan>;
	private copyable: boolean | null;
	private notificationTopic: string | null;

	constructor(
		path: string = "",
		settings: Object = {},
		icon: string = "",
		name: string = "",
		description: string = "",
		id: number = 0,
		block: Block | null = null,
		moderatorPath: string = "",
		presenterPath: string = "",
		title: string = "",
		visible: boolean = true,
		visible_in_hybrid: boolean = true,
		models: Array<any> = [],
		templates: Array<BlockTemplate> = [],
		block_template: BlockTemplate = new BlockTemplate(),
		payment_plans: Array<PaymentPlan> = [],
		copyable: boolean | null = false,
		notificationTopic: string | null = null
	) {
		this.path = path
		this.settings = settings
		this.icon = icon
		this.name = name
		this.description = description
		this.unsavedSettings = settings
		this.id = id
		this.block = block
		this.onDelete = [];
		this.moderatorPath = moderatorPath;
		this.presenterPath = presenterPath;
		this.title = title;
		this.visible = visible;
		this.visible_in_hybrid = visible_in_hybrid;
		this.models = models;
		this.templates = templates;
		this.block_template = block_template
		this.paymentPlans = payment_plans;
		this.copyable = copyable;
		this.notificationTopic = notificationTopic;
	}

	public static fromJSON(json: any): EventBlock {
		var block = new EventBlock()
		if (!json) return block;
		Object.assign(block, json)
		var unsavedSettings = {}
		if (json.settings != '{}')
			Object.assign(unsavedSettings, json.settings)
		block.$unsavedSettings = unsavedSettings
		block.name = json.name ? json.name : json.dynamic_block.name
		block.description = json.dynamic_block.description
		block.path = json.dynamic_block.settings_react_path
		block.moderatorPath = json.dynamic_block.moderator_react_path;
		block.presenterPath = json.dynamic_block.presenter_react_path;
		block.$notificationTopic = json.notification_topic;

		if (json.dynamic_block.templates) {
			const templates = [];
			for (let tempJson of json.dynamic_block.templates) {
				templates.push(BlockTemplate.fromJSON(tempJson));
			}
			block.$templates = templates;
		}

		if (json.block_template) {
			block.$block_template = BlockTemplate.fromJSON(json.block_template);
		}

		block.block = Block.fromJSON(json.dynamic_block)

		if (json.models) {
			block.$models = json.models;
		}
		var _plans: Array<PaymentPlan> = [];
		if (json.payment_plans) {
			json.payment_plans.forEach((plan: Object) => {
				_plans.push(PaymentPlan.fromJSON(plan));
			})
		}

		block.$paymentPlans = _plans;


		return block
	}
	public isNew(): boolean {
		return Object.keys(this.$unsavedSettings).length == 0 || this.$unsavedSettings == {};
	}
	public addOnDelete(e: any) {
		this.onDelete.push(e);
	}
	/**
	 * GETTERS
	 */
	public get $notificationTopic(): string | null { return this.notificationTopic; }
	public get $onDelete(): any[] { return this.onDelete; }
	public get $path(): string { return this.path; }
	public get $settings(): Object { return this.settings; }
	public get $icon(): string { return this.icon; }
	public get $name(): string { return this.name; }
	public get $description(): string { return this.description; }
	public get $unsavedSettings(): Object { return this.unsavedSettings; }
	public get $isChanged(): boolean { return this.isChanged; }
	public get $id(): number { return this.id; }
	public get $block(): Block | null { return this.block; }
	public get $moderatorPath(): string { return this.moderatorPath; }
	public get $presenterPath(): string { return this.presenterPath; }
	public get $eventBlockPayload(): Object {
		const visibleFor = [];
		for (let plan of this.paymentPlans) {
			visibleFor.push(plan.$id);
		}
		var out = {
			settings: this.unsavedSettings,
			visible_for: visibleFor,
			visible: this.visible,
			visible_in_hybrid: this.visible_in_hybrid,
			block_template_id: this.block_template.$id
		}

		return out
	}
	public get $title(): string { return this.title; }
	public get $visible(): boolean { return this.visible; }
	public get $visible_in_hybrid(): boolean { return this.visible_in_hybrid; }
	public get $models(): Array<any> { return this.models; }
	public get $templates(): Array<BlockTemplate> { return this.templates; }
	public get $block_template(): BlockTemplate { return this.block_template; }
	public get $paymentPlans(): Array<PaymentPlan> { return this.paymentPlans; }
	public get $copyable(): boolean | null { return this.copyable; }

	/**
	 * SETTERS
	 */
	public set $notificationTopic(value: string | null) { this.notificationTopic = value; }
	public set $name(value: string) { this.name = value; }
	public set $path(value: string) { this.path = value; }
	public set $settings(value: Object) { this.settings = value; }
	public set $unsavedSettings(value: Object) { this.unsavedSettings = value; }
	public set $isChanged(value: boolean) { this.isChanged = value; }
	public set $moderatorPath(value: string) { this.moderatorPath = value; }
	public set $title(value: string) { this.title = value; }
	public set $visible(value: boolean) { this.visible = value; }
	public set $visible_in_hybrid(value: boolean) { this.visible_in_hybrid = value; }
	public set $presenterPath(value: string) { this.presenterPath = value; }
	public set $description(value: string) { this.description = value; }
	public set $models(value: Array<any>) { this.models = value; }
	public set $templates(value: Array<BlockTemplate>) { this.templates = value; }
	public set $block_template(value: BlockTemplate) { this.block_template = value; }
	public set $paymentPlans(value: Array<PaymentPlan>) { this.paymentPlans = value; }
	public set $copyable(value: boolean | null) { this.copyable = value; }
}
