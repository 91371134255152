import React from 'react';
import { SnackbarContext, defaultDuration, defaultPosition, positions } from './Snackbar';

export function withSnackbar(WrappedComponent: any,
	{ position = defaultPosition, style = {}, closeStyle = {} } = {}) {
	void style;
	void closeStyle;
	return class extends React.Component {
		static contextType = SnackbarContext

		constructor(props: any) {
			super(props)
			this.open = this.open.bind(this)
			this.close = this.close.bind(this)
		}
	  
		open(text = '', duration = defaultDuration, accept: any, decline: any) {
			const { openSnackbar } = this.context
		
			// If no correct position is passed, 'bottom-center' is set
			if (!positions.includes(position)) {
				position = defaultPosition
			}
		
			openSnackbar(text, duration, accept, decline)
		}
		
		close() {
			const { closeSnackbar } = this.context
			closeSnackbar()
		}
	
		render() {
			return (
				<WrappedComponent
				openSnackbar={this.open}
				closeSnackbar={this.close}
				{...this.props}
				/>
			)
		}

	}
}