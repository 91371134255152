import React from 'react';


export default function PageError505() {
  return (
    <>
      
    </>
  );
}
