import React, { useContext, useEffect, useState } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UncontrolledTooltip, Button } from 'reactstrap';
import { Collapse } from "reactstrap"

import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import {
	setSidebarToggleMobile,
	setSidebarToggle,
} from '../../reducers/ThemeOptions';
import { Context as OrganisationContext } from '../../context/OrganisationContext';

import { NavLink, useParams } from 'react-router-dom';
import { Context as EventContext } from '../../context/EventContext';
import {
	Settings,
	Copy,
	BookOpen,
	ChevronRight,
	Lock,
	Layout,
	Activity,
	AlertTriangle,
	Package,
	User,
	FileMinus
} from 'react-feather';

const SidebarCollapsed = (props) => {
	const { state, dispatch } = useContext(EventContext);
	const { organisationState, organisationDispatch } = useContext(OrganisationContext);

	const [path, setPath] = useState('')
	let { id } = useParams();

	const [isOpen, setIsOpen] = useState('');

	const toggleSidebar = () => {
		setSidebarToggle(!sidebarToggle);
	};

	const toggleSidebarMobile = () => {
		setSidebarToggleMobile(!sidebarToggleMobile);
	};

	useEffect(() => {
		setPath(window.location.pathname.split('/')[1])
	}, [])

	const {
		sidebarShadow,
		sidebarStyle,
		sidebarToggleMobile,
		setSidebarToggleMobile,

		sidebarToggle,
		setSidebarToggle
	} = props;

	const eventSidebarOptions = [
		{
			title: 'Settings',
			slug: 'settings',
			icon: <Settings />,
			minimalRole: 'Event Manager',
			hide: state && !state.event.hasUserRole("Event Manager"),
			items: [
				{
					to: `/event/${id}`,
					icon: (
						<span>
							<Settings />
						</span>
					),
					tooltip: "General settings",
					minimalRole: 'Event Manager',
					hide: state && !state.event.hasUserRole("Event Manager"),
				},
				{
					to: `/event/${id}/security`,
					icon: (
						<span>
							<Lock />
						</span>
					),
					tooltip: "Security settings",
					minimalRole: 'Event Manager',
					hide: state && !state.event.hasUserRole("Event Manager"),
				},
				{
					to: `/event/${id}/payments`,
					icon: (
						<span>
							<FontAwesomeIcon icon={['fas', 'user-tag']} className="font-size-lg" />
						</span>
					),
					tooltip: "Participant plans",
					minimalRole: 'Event Manager',
					hide: state && !state.event.hasUserRole("Event Manager"),

				},
				{
					to: `/event/${id}/states`,
					icon: (
						<span>
							<FontAwesomeIcon icon={['far', 'clock']} className="font-size-lg" />
						</span>
					),
					tooltip: "Event state settings",
					minimalRole: 'Event Manager',
					hide: state && !state.event.hasUserRole("Event Manager"),
				},
				{
					to: `/event/${id}/translations`,
					icon: (
						<span>
							<FontAwesomeIcon icon={['fa', 'language']} className="font-size-lg" />
						</span>
					),
					tooltip: "Translation settings",
					minimalRole: 'Event Manager',
					hide: state && !state.event.hasUserRole("Event Manager"),
				},
				{
					to: `/event/${id}/tags`,
					icon: (
						<span>
							<FontAwesomeIcon icon={['fas', 'tags']} className="font-size-lg" />
						</span>
					),
					tooltip: "Event meta data",
					minimalRole: 'Event Manager',
					hide: state && !state.event.hasUserRole("Event Manager"),
				},
			]
		},
		{
			title: 'Event management',
			slug: 'event-management',
			icon: (<span>
				<FontAwesomeIcon icon={['fas', 'tools']} className="font-size-lg" />
			</span>),
			hide: state && state.event.hasUserRole("Event Content Manager", false),
			items: [
				{
					to: `/event/${id}/moderator`,
					icon: (
						<span>
							<FontAwesomeIcon icon={['fas', 'headset']} className="font-size-lg" />
						</span>
					),
					tooltip: "Moderator panels",
					minimalRole: 'Event Moderator',
					hide: state && !state.event.hasUserRole("Event Moderator"),
				},
				{
					to: `/event/${id}/presenter`,
					icon: (
						<span>
							<FontAwesomeIcon icon={['fas', 'chalkboard-teacher']} className="font-size-lg" />
						</span>
					),
					tooltip: "Presenter panels",
					minimalRole: 'Event Presenter',
					hide: state && !state.event.hasUserRole("Event Presenter"),
				},
				{
					to: `/event/${id}/elevatedUsers`,
					icon: (
						<span>
							<FontAwesomeIcon icon={['fas', 'users-cog']} className="font-size-lg" />
						</span>
					),
					tooltip: "Dashboard users",
					minimalRole: 'Event Manager',
					hide: state && !state.event.hasUserRole("Event Manager"),

				},
				{
					to: `/event/${id}/users`,
					icon: (
						<span>
							<FontAwesomeIcon icon={['fas', 'users']} className="font-size-lg" />
						</span>
					),
					tooltip: "Event users",
					minimalRole: 'Event Manager',
					hide: state && !state.event.hasUserRole("Event Manager"),

				},
				{
					to: `/event/${id}/analytics`,
					icon: (
						<span>
							<Activity />
						</span>
					),
					tooltip: "Analytics",
					minimalRole: 'Event Manager',
					hide: state && !state.event.hasUserRole("Event Manager"),
				}
			]
		},
		{
			title: "Layout",
			slug: 'layout',
			icon: (<span>
				<FontAwesomeIcon icon={['fas', 'pencil-ruler']} className="font-size-lg" />
			</span>),
			minimalRole: 'Event Content Manager',
			hide: state && !state.event.hasUserRole("Event Content Manager"),
			items: [
				{
					to: `/event/${id}/layout/navbar`,
					icon: (
						<span>
							<FontAwesomeIcon icon={['fas', 'bars']} className="font-size-lg" />

						</span>
					),
					tooltip: "Main navigation",
					minimalRole: "Event Content Manager",
					hide: state && !state.event.hasUserRole("Event Content Manager"),
				},
				{
					to: `/event/${id}/layout/pages`,
					icon: (
						<span>
							<Layout />
						</span>
					),
					tooltip: "Pages",
					minimalRole: "Event Content Manager",
					hide: state && !state.event.hasUserRole("Event Content Manager"),
				},
				{
					to: `/event/${id}/layout/footer`,
					icon: (
						<span>
							<FileMinus />
						</span>
					),
					tooltip: "Footer",
					minimalRole: "Event Content Manager",
					hide: state && !state.event.hasUserRole("Event Content Manager"),
				},
				{
					to: `/event/${id}/layout/settings`,
					icon: (
						<span>
							<FontAwesomeIcon icon={['fas', 'palette']} className="font-size-lg" />

						</span>
					),
					tooltip: "Layout settings",
					minimalRole: "Event Content Manager",
					hide: state && !state.event.hasUserRole("Event Content Manager"),
				},
				{
					to: `/event/${id}/layout/preview`,
					icon: (
						<span>
							<FontAwesomeIcon icon={['fas', 'eye']} className="font-size-lg" />

						</span>
					),
					tooltip: "Event preview",
					minimalRole: "Event Content Manager",
					hide: state && !state.event.hasUserRole("Event Content Manager"),
				},
				{
					to: `/event/${id}/layout/media`,
					icon: (
						<span>
							<FontAwesomeIcon icon={['fas', 'photo-video']} className="font-size-lg" />

						</span>
					),
					tooltip: "Media library",
					minimalRole: "Event Content Manager",
					hide: state && !state.event.hasUserRole("Event Content Manager"),
				},
				{
					to: `/event/${id}/layout/emojis`,
					icon: (
						<span>
							<FontAwesomeIcon icon={['fas', 'smile']} className="font-size-lg" />

						</span>
					),
					tooltip: "Emoji reactions",
					minimalRole: "Event Content Manager",
					hide: state && !state.event.hasUserRole("Event Content Manager"),
				},
			]
		},
		{
			title: 'Services',
			slug: 'services',
			minimalRole: 'Event Manager',
			icon: (
				<span>
					<Package />
				</span>
			),
			hide: state && !state.event.hasUserRole("Event Content Manager"),
			items: [
				{
					to: `/event/${id}/services/oauth`,
					icon: (
						<FontAwesomeIcon icon={['fas', 'user-shield']} className="font-size-lg" />
					),
					tooltip: "OAuth",
					minimalRole: "Event Manager",
					hide: state && !state.event.hasUserRole("Event Manager")
				},
				{
					to: `/event/${id}/services/networking`,
					icon: (
						<FontAwesomeIcon icon={['fas', 'network-wired']} className="font-size-lg" />
					),
					tooltip: "Networking",
					minimalRole: 'Event Manager',
					hide: state && !state.event.hasUserRole("Event Manager"),
				},
				{
					to: `/event/${id}/services/speakers`,
					icon: (
						<FontAwesomeIcon icon={['fas', 'microphone']} className="font-size-lg" />
					),
					tooltip: "Speakers",
					minimalRole: 'Event Content Manager',
					hide: state && !state.event.hasUserRole("Event Content Manager"),
				},
				{
					to: `/event/${id}/services/exhibitions`,
					icon: (
						<FontAwesomeIcon icon={['fas', 'store']} className="font-size-lg" />
					),
					tooltip: "Exhibitions",
					minimalRole: 'Event Content Manager',
					hide: state && !state.event.hasUserRole("Event Content Manager"),
				},
				{
					to: `/event/${id}/services/hybrid`,
					icon: (
						<FontAwesomeIcon icon={['fas', 'bezier-curve']} className="font-size-lg" />
					),
					tooltip: "Hybrid",
					minimalRole: 'Event Manager',
					hide: state && !state.event.hasUserRole("Event Manager"),
				},
				{
					to: `/event/${id}/services/sessions`,
					icon: (
						<FontAwesomeIcon icon={['fas', 'chalkboard']} className="font-size-lg" />
					),
					tooltip: "Sessions",
					minimalRole: 'Event Content Manager',
					hide: state && !state.event.hasUserRole("Event Content Manager"),
				},
				{
					to: `/event/${id}/services/rooms`,
					icon: (
						<FontAwesomeIcon icon={['fas', 'store-alt']} className="font-size-lg" />
					),
					tooltip: "Rooms",
					minimalRole: 'Event Manager',
					hide: state && !state.event.hasUserRole("Event Content Manager"),
				},
				{
					to: `/event/${id}/services/sync`,
					icon: (
						<FontAwesomeIcon icon={['fas', 'sync']} className="font-size-lg" />
					),
					tooltip: "External sync",
					minimalRole: 'Event Manager',
					hide: state && !state.event.hasUserRole("Event Manager"),
				},
				{
					to: `/event/${id}/services/theo`,
					icon: (
						<FontAwesomeIcon icon={['fas', 'play-circle']} className="font-size-lg" />
					),
					tooltip: "TheoLive",
					minimalRole: 'Event Manager',
					hide: state && !state.event.hasUserRole("Event Manager"),
				},
				{
					to: `/event/${id}/services/tours`,
					icon: (
						<FontAwesomeIcon icon={['fas', 'hands-helping']} className="font-size-lg" />
					),
					tooltip: "Guided tours",
					minimalRole: 'Event Manager',
					hide: state && !state.event.hasUserRole("Event Manager"),
				},
				{
					to: `/event/${id}/services/mollie`,
					icon: (
						<FontAwesomeIcon icon={['fas', 'money-bill']} className="font-size-lg" />
					),
					tooltip: "Mollie payments",
					minimalRole: 'Event Manager',
					hide: state && !state.event.hasUserRole("Event Manager"),
				}
			]
		},
		{
			title: 'Advanced',
			slug: 'advanced',
			minimalRole: 'Event Manager',
			icon: (
				<span>
					<FontAwesomeIcon icon={['fas', 'cogs']} className="font-size-lg" />
				</span>
			),
			hide: state && !state.event.hasUserRole("Event Manager"),
			items: [
				{
					to: `/event/${id}/advanced/datasources`,
					icon: (
						<span>
							<FontAwesomeIcon icon={['fas', 'database']} className="font-size-lg" />
						</span>
					),
					tooltip: "Datasources",
					minimalRole: 'Event Manager',
					hide: state && !state.event.hasUserRole("Event Manager"),

				},
				{
					to: `/event/${id}/advanced/mailing`,
					icon: (
						<span>
							<FontAwesomeIcon icon={['fas', 'envelope']} className="font-size-lg" />
						</span>
					),
					tooltip: "E-mail management",
					minimalRole: 'Event Manager',
					hide: state && !state.event.hasUserRole("Event Manager"),

				},
				{
					to: `/event/${id}/advanced/flows`,
					icon: (
						<span>
							<FontAwesomeIcon icon={['fas', 'directions']} className="font-size-lg" />
						</span>
					),
					tooltip: "Flow management",
					minimalRole: 'Event Manager',
					hide: state && !state.event.hasUserRole("Event Manager"),
				},
				{
					to: `/event/${id}/advanced/settings`,
					icon: (
						<span>
							<AlertTriangle />
						</span>
					),
					tooltip: "Advanced settings",
					minimalRole: 'Event Manager',
					hide: state && !state.event.hasUserRole("Event Manager"),
				},
				{
					to: `/event/${id}/advanced/logs`,
					icon: (
						<span>
							<FontAwesomeIcon icon={['fas', 'list-ul']} className="font-size-lg" />
						</span>
					),
					tooltip: "Activity logs",
					minimalRole: 'Event Manager',
					hide: state && !state.event.hasUserRole("Event Manager"),
				},
				{
					to: `/event/${id}/archiving`,
					icon: (
						<span>
							<FontAwesomeIcon icon={['fas', 'archive']} className="font-size-lg" />
						</span>
					),
					tooltip: "Archiving",
					minimalRole: 'Event Manager',
					hide: state && !state.event.hasUserRole("Event Manager"),
				},
				{
					to: `/event/${id}/advanced/domains`,
					icon: (
						<span>
							<FontAwesomeIcon icon={['fas', 'compass']} className="font-size-lg" />
						</span>
					),
					tooltip: "Custom domains",
					minimalRole: 'Event Manager',
					hide: state && !state.event.hasUserRole("Event Manager"),
				},
				{
					to: `/event/${id}/advanced/template`,
					icon: (
						<span>
							<FontAwesomeIcon icon="object-group" />
						</span>
					),
					tooltip: "Convert to template",
					minimalRole: 'Admin',
					hide: !state || !state.event,
				},
			]
		}
	]

	const organisationSidebarOption = [
		{
			title: 'Dashboard',
			to: `/organisation/${id}/dashboard`,
			icon: <BookOpen />,
			minimalRole: 'Organisation Manager',
			hide: organisationState && !organisationState.organisation.hasUserRole("Organisation Manager"),
		},
		{
			title: 'General',
			to: "/organisation/" + id,
			icon: <FontAwesomeIcon icon="circle-info" />,
			minimalRole: 'Organisation Manager',
			hide: organisationState && !organisationState.organisation.hasUserRole("Organisation Manager"),
		},
		{
			title: 'Managers',
			to: "/organisation/" + id + "/users",
			icon: <User />,
			minimalRole: 'Project Manager',
			hide: organisationState && !organisationState.organisation.hasUserRole("Project Manager"),
		},
		{
			title: 'Templates',
			to: `/organisation/${id}/templates`,
			icon: <Copy />,
			minimalRole: 'Organisation Manager',
			hide: organisationState && !organisationState.organisation.hasUserRole("Organisation Manager"),
		},
		{
			title: 'Settings',
			slug: "settings",
			icon: <Settings />,
			minimalRole: 'Organisation Manager',
			hide: organisationState && !organisationState.organisation.hasUserRole("Organisation Manager"),
			items: [
				{
					to: `/organisation/${id}/media`,
					icon: (
						<span>
							<FontAwesomeIcon icon={['fas', 'photo-video']} className="font-size-lg" />
						</span>
					),
					tooltip: "Media library",
					minimalRole: "Organisation Manager",
					hide: state && !state.event.hasUserRole("Organisation Manager"),
				},
				{
					to: `/organisation/${id}/theo`,
					icon: (
						<FontAwesomeIcon icon={['fas', 'play-circle']} className="font-size-lg" />
					),
					tooltip: "TheoLive",
					minimalRole: 'Organisation Manager',
					hide: state && !state.event.hasUserRole("Organisation Manager"),
				},
				{
					to: `/organisation/${id}/faqs`,
					icon: (
						<FontAwesomeIcon icon={['fas', 'question']} className="font-size-lg" />
					),
					tooltip: "FAQ Entries",
					minimalRole: 'Organisation Manager',
					hide: state && !state.event.hasUserRole("Organisation Manager"),
				},
				{
					to: `/organisation/${id}/mollie`,
					icon: (
						<FontAwesomeIcon icon={['fas', 'money-bill']} className="font-size-lg" />
					),
					tooltip: "Mollie payments",
					minimalRole: 'Organisation Manager',
					hide: state && !state.event.hasUserRole("Organisation Manager"),
				},
				{
					to: `/organisation/${id}/datasources`,
					icon: (
						<FontAwesomeIcon icon={['fas', 'database']} className="font-size-lg" />
					),
					tooltip: "Datasources",
					minimalRole: 'Organisation Manager',
					hide: state && !state.event.hasUserRole("Organisation Manager"),
				},
				{
					to: `/organisation/${id}/mailing`,
					icon: (
						<FontAwesomeIcon icon={['fas', 'envelope']} className="font-size-lg" />
					),
					tooltip: "E-mail management",
					minimalRole: 'Organisation Manager',
					hide: state && !state.event.hasUserRole("Organisation Manager"),
				},
			]
		}

	]

	const SidebarOrganisations = () => {
		return (
			<div
				className={clsx('app-sidebar', sidebarStyle, {
					'app-sidebar--shadow': sidebarShadow
				})}>
				<div className="app-sidebar--header">
					<div className="app-sidebar-logo">
						<NavLink
							to="/dashboard"
							title="Go to dashboard"
							className="app-sidebar-logo"
						>
							<div className="app-sidebar-logo--icon">
								<img
									alt="logo"
									src={organisationState.organisation.$logo}
								/>
							</div>
							<div className="app-sidebar-logo--text text-center pl-0">
								<span>Organisation</span>

								<b>{organisationState.organisation.$name}</b>
							</div>
						</NavLink>
					</div>
				</div>
				<div className="app-sidebar--content">
					<PerfectScrollbar style={{ display: 'flex', flexFlow: 'column' }}>
						<div className="sidebar-navigation">
							<div className="sidebar-header">
								<span>Navigation menu</span>
							</div>
							<ul>
								{organisationSidebarOption.map((parent, parentIndex) => {
									if (!parent.hide) {
										if (!parent.items || parent.items.length == 0) {
											return (
												<li key={"sidebar-org-" + parentIndex}>
													<NavLink
														end
														onClick={(e) => {
															setIsOpen(parent.slug)
														}}
														to={parent.to}
														className={"menu-item"}>
														<span className="sidebar-icon" style={{ height: 'auto' }}>
															{parent.icon}
														</span>
														<span className="sidebar-item-label">{parent.title}</span>
													</NavLink>
												</li>
											)
										}
										return (
											<li key={parent.slug}>
												<a
													onClick={() => {
														setIsOpen(parent.slug);
														if (parent.to) {
															window.location.pathname = parent.to
														}
													}}
													className={clsx('custom-menu-item', { active: isOpen == parent.slug })}
												>
													<span className="sidebar-icon" style={{ height: 'auto' }}>
														{parent.icon}
													</span>
													<span className="sidebar-item-label">{parent.title}</span>
													{(parent.items && parent.items.length > 0) && (
														<span className="sidebar-icon-indicator">
															<ChevronRight />
														</span>
													)}
												</a>
												{(parent.items && parent.items.length > 0) && (
													<Collapse isOpen={isOpen == parent.slug}>
														<ul style={{ listStyle: 'none' }}>
															{parent.items.map((child, childIndex) => {
																if (!child.hide) {
																	return (
																		<li key={"sidebar-collapsed-" + childIndex}>
																			<NavLink to={child.to} className="menu-item">
																				<span className="sidebar-icon" style={{ height: 'auto' }}>
																					{child.icon}
																				</span>
																				<span className="sidebar-item-label">{child.tooltip}</span>
																			</NavLink>
																		</li>
																	)
																}
															})}
														</ul>
													</Collapse>
												)}
											</li>
										)
									}
								})}
							</ul>
						</div>
						<div style={{ position: 'relative', minHeight: '100px', display: 'flex', flex: '1 1 auto' }}>
							<div className="text-center mb-2 sidebar-back-btn" >
								<Button
									tag={Link}
									color="warning"
									className="m-1 p-0 d-inline-block shadow-none text-center font-size-lg d-40 rounded"
									to="/organisations"
									id="CollapsedSidebarTooltip131"
								>
									<FontAwesomeIcon icon={['fas', 'arrow-left']} />
								</Button>
								<UncontrolledTooltip
									popperClassName="tooltip-secondary text-nowrap"
									placement="right"
									target="CollapsedSidebarTooltip131"
									container=".app-sidebar--content"
									boundariesElement="window"
								>
									Back to organisations
								</UncontrolledTooltip>
							</div>
						</div>
					</PerfectScrollbar>
				</div>
			</div>

		)
	}

	const SidebarEvents = () => {
		return (
			<React.Fragment key="sidebar-events">
				<div
					className={clsx('app-sidebar', sidebarStyle, {
						'app-sidebar--shadow': sidebarShadow
					})}>
					<div className="app-sidebar--header">
						<div className="app-sidebar-logo">
							<NavLink
								to="/dashboard"
								title="Go to dashboard"
								className="app-sidebar-logo"
							>
								<div className="app-sidebar-logo--icon">
									<img
										alt="logo"
										src={state.event.$logo}
									/>
								</div>
								<div>
									<div className="app-sidebar-logo--text">
										<b>{state.event.$title}</b>
									</div>
									{state.event.$internal_title != '' &&
										<div className="app-sidebar-logo--text text-center" style={{ color: 'white' }}>
											<p className='mb-0 mt-1'>{state.event.$internal_title}</p>
										</div>
									}
								</div>
							</NavLink>
						</div>
						<button
							onClick={toggleSidebar}
							className="btn btn-sm collapse-sidebar-btn"
							id="CollapseSidebarTooltip">
							<FontAwesomeIcon icon={['far', 'dot-circle']} size="lg" />
						</button>
						<UncontrolledTooltip
							target="CollapseSidebarTooltip"
							container=".app-sidebar"
							placement="right">
							Collapse sidebar
						</UncontrolledTooltip>
						<button
							className={clsx(
								'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
								{ 'is-active': sidebarToggleMobile }
							)}
							onClick={toggleSidebarMobile}>
							<span className="hamburger-box">
								<span className="hamburger-inner" />
							</span>
						</button>
						<button
							onClick={toggleSidebar}
							className="expand-sidebar-btn btn btn-sm"
							id="ExpandSidebarTooltip">
							<FontAwesomeIcon icon={['fas', 'arrows-alt-h']} />
						</button>
						<UncontrolledTooltip
							target="ExpandSidebarTooltip"
							container=".app-sidebar"
							placement="right">
							Expand sidebar
						</UncontrolledTooltip>
					</div>
					<div className="app-sidebar--content">
						<PerfectScrollbar style={{ display: 'flex', flexFlow: 'column' }}>
							<div className="sidebar-navigation">
								<div className="sidebar-header">
									<span>Navigation menu</span>
								</div>
								<ul>
									{eventSidebarOptions.map((parent, parentIndex) => {
										if (!parent.hide) {

											return (
												<li key={parent.slug}>
													<a
														onClick={() => {
															setIsOpen(parent.slug);
														}}
														className={clsx('custom-menu-item', { active: isOpen == parent.slug })}
													>
														<span className="sidebar-icon" style={{ height: 'auto' }}>
															{parent.icon}
														</span>
														<span className="sidebar-item-label">{parent.title}</span>
														{parent.items.length > 0 && (
															<span className="sidebar-icon-indicator">
																<ChevronRight />
															</span>
														)}
													</a>
													{parent.items.length > 0 && (
														<Collapse isOpen={isOpen == parent.slug}>
															<ul style={{ listStyle: 'none' }}>
																{parent.items.map((child, childIndex) => {
																	if (!child.hide) {
																		return (
																			<li key={"sidebar-collapsed-" + childIndex}>
																				<NavLink to={child.to} className="menu-item">
																					<span className="sidebar-icon" style={{ height: 'auto' }}>
																						{child.icon}
																					</span>
																					<span className="sidebar-item-label">{child.tooltip}</span>
																				</NavLink>
																			</li>
																		)
																	}
																})}
															</ul>
														</Collapse>
													)}
												</li>
											)
										}
									})}
								</ul>

							</div>
							<div style={{ position: 'relative', minHeight: '100px', display: 'flex', flex: '1 1 auto' }}>
								<div className="text-center mb-2 sidebar-back-btn" >
									<Button
										tag={Link}
										color="warning"
										className="m-1 p-0 d-inline-block shadow-none text-center font-size-lg d-40 rounded"
										to="/events"
										id="CollapsedSidebarTooltip131"
									>
										<FontAwesomeIcon icon={['fas', 'arrow-left']} />
									</Button>
									<UncontrolledTooltip
										popperClassName="tooltip-secondary text-nowrap"
										placement="right"
										target="CollapsedSidebarTooltip131"
										container=".app-sidebar--content"
										boundariesElement="window"
									>
										Back to events
									</UncontrolledTooltip>
								</div>
							</div>
						</PerfectScrollbar>

					</div>
				</div>
			</React.Fragment>
		)
	}

	return (
		<>
			<style>
				{`
				.menu-item::after { display: none;}
				.custom-menu-item:hover { 
					cursor: pointer;
				}
				.sidebar-back-btn {
					position: absolute;
					bottom: 10px;
					width: 100%;
				}
				@media only screen and (max-width: 1280px) {
					.sidebar-back-btn {
						position: relative;
						margin-top: 3rem;
						bottom: 10px;
						width: 100%;
					}	
				}
			`}
			</style>
			{/*
			Solved https://dbvideo.atlassian.net/browse/WU-1397 by changing the options to Components instead of function.
			Page crashed after hovering over any of the tooltips, followed by navigating via the sidebar.
			 */}
			{path === 'event' && (
				<SidebarEvents />
			)}
			{path == 'organisation' && (
				<SidebarOrganisations />
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	sidebarShadow: state.ThemeOptions.sidebarShadow,
	sidebarStyle: state.ThemeOptions.sidebarStyle,
	sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
	sidebarToggle: state.ThemeOptions.sidebarToggle,
});

const mapDispatchToProps = (dispatch) => ({
	setSidebarToggle: (enable) => dispatch(setSidebarToggle(enable)),

	setSidebarToggleMobile: (enable) =>
		dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarCollapsed);
