import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useState, useMemo, useEffect, useContext } from 'react';
import { Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { Context as EventContext } from '../../context/EventContext'
import { QRCode } from 'react-qrcode-logo'
import Switch from "rc-switch";
import Nouislider from 'nouislider-react';

interface Props {
	isOpen: boolean;
	onClose: () => {};
}
const EventQRModal: FC<Props> = (props) => {
	const { state, dispatch } = useContext(EventContext);
	const [showLogo, setShowLogo] = useState(true)

	const size = 250;

	const open = useMemo(() => props.isOpen, [props.isOpen])

	const [logoFactor, setLogoFactor] = useState(30)

	const [logoRatio, setLogoRatio] = useState(1);

	const calculateRatio = async () => {
		if (!state.event.$logo) return;

		const img = new Image();
		img.src = state.event.$logo;

		img.onload = () => {
			const ratio = img.height / img.width;
			setLogoRatio(ratio);
		}
	}

	useEffect(() => {
		calculateRatio();
	}, [])


	return (
		<Modal
			centered
			size="lg"
			isOpen={open}>
			<ModalHeader
				toggle={(e) => {
					props.onClose()
				}}
			>
				<FontAwesomeIcon icon="qrcode" size="lg" className="mr-3" />
				{state.event.$title}: QR Code
			</ModalHeader>
			<ModalBody>
				<div className="w-100 text-center">
					<QRCode
						value={state.event.$event_url}
						size={size}
						ecLevel='H'
						logoImage={showLogo ? state.event.$logo : ""}
						logoWidth={size * logoFactor / 100}
						logoHeight={(size * logoFactor / 100) * logoRatio}
						logoPadding={5}
						removeQrCodeBehindLogo={true}
					/>
				</div>
				<Row>
					<Col>
						<FormGroup className="align-items-center flex-column d-flex justify-content-center">
							<Label className="mb-0 mr-2">Show logo:</Label>
							<div>

								<Switch
									className="switch-small"
									defaultChecked={true}
									onChange={(e) => {
										setShowLogo(e);
									}} />
							</div>
						</FormGroup>
					</Col>
					<Col>
						{showLogo &&
							<>
								<FormGroup className="align-items-center flex-column d-flex justify-content-center">
									<Label className="mr-2">Logo size:</Label>
									<Input
										type="range"
										min="0"
										max="100"
										value={logoFactor}
										step={5}
										onChange={(e) => {
											setLogoFactor(parseInt(e.target.value));
										}}
									/>
								</FormGroup>
								{logoFactor > 30 &&
									<Label style={{ color: "#ff0000" }}>(Please check manually if the QR code still works)</Label>
								}
							</>
						}
					</Col>
				</Row>
			</ModalBody>
		</Modal>
	)
}
export default EventQRModal;