import React, { useContext, useState } from 'react';

import clsx from 'clsx';

import { connect } from 'react-redux';

import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import { Button } from 'reactstrap'

import HeaderDots from '../../layout-components/HeaderDots';
import HeaderDrawer from '../../layout-components/HeaderDrawer';
import HeaderUserbox from '../../layout-components/HeaderUserbox';
import HeaderSearch from '../../layout-components/HeaderSearch';
import HeaderMenu from '../../layout-components/HeaderMenu';
import { Context as EventContext } from '../../context/EventContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EventQRModal from '../../components/EventQRModal';

const Header = (props) => {
	const {
		headerShadow,
		headerBgTransparent,
		sidebarToggleMobile,
		setSidebarToggleMobile,
	} = props;
	const { state, dispatch } = useContext(EventContext);

	const [qrModalOpen, setQrModalOpen] = useState(false)

	const toggleSidebarMobile = () => {
		setSidebarToggleMobile(!sidebarToggleMobile);
	};

	return (
		<>
			<div
				className={clsx('app-header', {
					'app-header--shadow': headerShadow,
					'app-header--opacity-bg': headerBgTransparent,
				})}
			>
				<div className="app-header--pane">
					<button
						className={clsx(
							'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
							{ 'is-active': sidebarToggleMobile }
						)}
						onClick={toggleSidebarMobile}
					>
						<span className="hamburger-box">
							<span className="hamburger-inner" />
						</span>
					</button>

					{/*<HeaderSearch />
					<HeaderMenu />*/}
				</div>
				<div className="app-header--pane">
					{/*<HeaderDots />
          <HeaderUserbox />
		 <HeaderDrawer />*/}
					{state && state.event && state.event.$id ? (
						<>
							<Button
								className="btn-lg m-2"
								color="neutral-info"
								onClick={(e) => {
									setQrModalOpen(true)
								}}>
								<FontAwesomeIcon icon={['fas', 'qrcode']} className="mr-3"></FontAwesomeIcon>
								Generate QR code
							</Button>
							<EventQRModal
								isOpen={qrModalOpen}
								onClose={(e) => {
									setQrModalOpen(false);
								}}
							/>
						</>
					) : ''}
					{state && state.event && state.event.$id ? (
						<Button
							className="btn-lg m-2"
							color="neutral-first"
							onClick={(e) => {
								window.open(state.event.$event_url, "_blank");
							}}>
							<FontAwesomeIcon icon={['fas', 'location-arrow']} className="mr-3"></FontAwesomeIcon>
							Go to event page
						</Button>
					) : ''}

				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	headerShadow: state.ThemeOptions.headerShadow,
	headerBgTransparent: state.ThemeOptions.headerBgTransparent,
	sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

const mapDispatchToProps = (dispatch) => ({
	setSidebarToggleMobile: (enable) =>
		dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
