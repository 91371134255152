import EventBlock from "./EventBlock"

export default class TemplateZone {
	private id: number | null
	private blocks: Array<EventBlock>
	private name: string
	private description: string
	private allow_grid: boolean;
	private zone_settings: any;
	constructor(
		id: number | null = null,
		blocks: Array<EventBlock> = [],
		name: string = "",
		description: string = "",
		allow_grid: boolean = true,
		zone_settings: any = {}
	) {
		this.id = id
		this.blocks = blocks
		this.name = name
		this.description = description
		this.allow_grid = allow_grid;
		this.zone_settings = zone_settings;
	}

	public static fromJSON(json: any): TemplateZone {
		var tempZone = new TemplateZone()
		Object.assign(tempZone, json)

		var blocks: Array<EventBlock> = []
		if (json.blocks) {
			json.blocks.forEach((block: Object) => {
				blocks.push(EventBlock.fromJSON(block))
			})
		}

		if (json.zone_settings) {
			if (typeof json.zone_settings == 'string') {
				tempZone.$zone_settings = JSON.parse(json.zone_settings);
			} else {
				tempZone.$zone_settings = json.zone_settings;
			}
		}


		tempZone.$blocks = blocks
		return tempZone
	}

	public get $id(): number | null {
		return this.id
	}

	public get $blocks(): Array<EventBlock> {
		return this.blocks
	}

	public get $name(): string {
		return this.name
	}

	public get $description(): string {
		return this.description
	}

	public get $allow_grid(): boolean {
		return this.allow_grid;
	}
	public get $zone_settings(): any {
		return this.zone_settings;
	}

	public set $id(value: number | null) {
		this.id = value
	}

	public set $blocks(value: Array<EventBlock>) {
		this.blocks = value
	}
	public set $allow_grid(value: boolean) {
		this.allow_grid = value;
	}
	public set $zone_settings(value: any) {
		this.zone_settings = value;
	}

	public addBlock(block: EventBlock) {
		this.blocks.push(block)
	}
	public deleteBlock(block: EventBlock) {
		for (var i = 0; i < this.blocks.length; i++) {
			if (this.blocks[i] === block) {
				for (let key in block.$onDelete) {
					const listener = block.$onDelete[key];
					listener('buh');
				}
				this.blocks.splice(i, 1)
			}
		}
	}
}