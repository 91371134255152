

export default class Language{
	private id: number;
	private name: string;
	private slug: string;
	private flag_slug: string;
	private is_default: boolean;
	
    constructor(
		id: number = -1,
		name: string = "",
		slug: string = "",
		flag_slug: string = "",
		is_default: boolean = false
	) {
        this.id = id;
        this.name = name;
        this.slug = slug;
        this.flag_slug = flag_slug;
        this.is_default = is_default;
    }

	public static fromJSON(json: any): Language {
		var lg: Language = new Language();
		Object.assign(lg, json);
		return lg;
	}
	public get $id() : number {
		return this.id;
	}
	public get $name(): string {
		return this.name;
	}

	public get $slug(): string {
		return this.slug;
	}

	public get $flag_slug(): string {
		return this.flag_slug;
	}

	public get $is_default(): boolean {
		return this.is_default;
	}

	
	public set $name(value: string) {
		this.name = value;
	}

	public set $slug(value: string) {
		this.slug = value;
	}

	public set $flag_slug(value: string) {
		this.flag_slug = value;
	}

	public set $is_default(value: boolean) {
		this.is_default = value;
	}
}