import React, { createContext, FC, useContext } from 'react';
import _uniqueId from 'lodash/uniqueId';
import {Context as UserContext} from '../../context/UserContext'
import {Context as EventContext} from '../../context/EventContext'
import { useNavigate } from 'react-router-dom';

interface AccessRightsProps{}

export const AccessRightsContext = createContext({});

const AccessRights: FC<AccessRightsProps> = ({ children }) => {
	const { userState, userDispatch } = useContext(UserContext);
	const navigate = useNavigate();

    const hasAccessRights = (min_role) => {
        if(!userState || !userState.user || userState.user.id == 0) {
            return false;
        }
        if(!userState.user.hasRole(min_role)) {
            navigate('/PageError403')
        }
    }

    return (
        <AccessRightsContext.Provider value={{ checkAccessRights: hasAccessRights }}>
            {children}
        </AccessRightsContext.Provider>
    )
}
export default AccessRights;