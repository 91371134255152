import TemplateZone from './TemplateZone';
import moment from 'moment';
import Language from './Language'
import EventTemplate from './EventTemplate'
import EventRoom from './EventRoom'
import Font from './Font'
import Service from './Service'
import Organisation from './Organisation'
import PaymentPlan from './PaymentPlan'
import MetaTag from './MetaTag'


export default class Event {
	private id: number | null;
	private title: string | null;
	private internal_title: string | null;
	private slug: string | null;
	private organisation_id: number | null;
	private organisation: Organisation | null;
	private logo: string | null;
	private background: string | null;
	private background_type: string | null;
	private color: string | null;
	private favicon: string | null;
	private event_gdpr: boolean;
	private event_gdpr_link: string | null;
	private security_type: string;
	private security_data: string | null;
	private event_template_id: number;
	private zones: Array<TemplateZone>;
	private estimated_viewers: number;
	private startDate: string;
	private endDate: string;
	private startHour: any;
	private endHour: any;
	private loginPageScripts: string | null;
	private eventPageScripts: string | null;
	private css: string | null;
	private start: string | null;
	private end: string | null;
	private translation_data: any | null;
	private states: any[] | null;
	private currentStateId: number | null;
	private show_title: boolean | null;
	private event_url: string | null;
	private services: Array<Service> | null;
	private rooms: Array<EventRoom> | null;
	private fonts: Array<Font> | null;
	private registration_layout_settings: any | null;
	private template: EventTemplate | null;
	private event_template_settings: any | null;
	private user_roles: Array<string> | null;
	private payment_plans: Array<PaymentPlan> | null;
	private archive_on: string | null;
	private TZ: string | null;
	private custom_domains: any[] | null;
	private loading_settings: string;
	private metaTag: MetaTag | null;
	private is_demo: boolean;

	constructor(
		id: number | null = null,
		title: string | null = '',
		internal_title: string | null = '',
		slug: string | null = '',
		organisation_id: number | null = null,
		logo: string | null = null,
		background: string | null = null,
		background_type: string | null = null,
		color: string | null = null,
		favicon: string | null = null,
		event_gdpr: boolean = false,
		event_gdpr_link: string | null = null,
		security_type: string = 'public',
		security_data: string | null = null,
		event_template_id: number = 1,
		zones: Array<TemplateZone> = [],
		estimated_viewers: number = 0,
		startDate: string = '',
		endDate: string = '',
		startHour: string = '',
		endHour: string = '',
		loginPageScripts: string | null = '',
		eventPageScripts: string | null = '',
		css: string | null = '',
		translation_data: any = {},
		states: any[] | null = [],
		show_title: boolean | null = false,
		event_url: string | null = '',
		services: Array<Service> | null = [],
		rooms: Array<EventRoom> | null = [],
		fonts: Array<Font> | null = [],
		registration_layout_settings: any | null = null,
		template: EventTemplate | null = null,
		template_settings: any | null = null,
		user_roles: Array<string> | null = [],
		payment_plans: Array<PaymentPlan> | null = [],
		archive_on: string | null = null,
		TZ: string | null = "",
		custom_domains: any[] | null = [],
		loading_settings: string = "{}",
		metaTag: MetaTag | null = null,
		organisation: Organisation | null = null,
		is_demo: boolean = false,
	) {
		this.id = id;
		this.title = title;
		this.internal_title = internal_title;
		this.slug = slug;
		this.organisation_id = organisation_id;
		this.logo = logo;
		this.background = background;
		this.background_type = background_type;
		this.color = color;
		this.favicon = favicon;
		this.event_gdpr = event_gdpr;
		this.event_gdpr_link = event_gdpr_link;
		this.security_type = security_type;
		this.security_data = security_data;
		this.event_template_id = event_template_id;
		this.zones = zones;
		this.estimated_viewers = estimated_viewers;
		this.startDate = startDate;
		this.endDate = endDate;
		this.startHour = startHour;
		this.endHour = endHour;
		this.loginPageScripts = loginPageScripts;
		this.eventPageScripts = eventPageScripts;
		this.css = css;
		this.states = states;
		this.show_title = show_title;
		this.services = services;
		this.rooms = rooms;
		this.fonts = fonts;
		this.registration_layout_settings = registration_layout_settings;
		this.template = template;
		this.event_template_settings = template_settings;
		this.user_roles = user_roles;
		this.archive_on = archive_on;
		this.organisation = organisation;


		this.start = this.startDate + ' ' + this.startHour;
		if (this.endDate != null && this.endDate.length > 0 && this.endHour != null && this.endHour.tostring().length > 0)
			this.end = this.endDate + ' ' + this.endHour;
		else
			this.end = null

		this.translation_data = translation_data;
		this.currentStateId = null;
		this.event_url = event_url;
		this.payment_plans = payment_plans;
		this.TZ = TZ;
		this.custom_domains = custom_domains;
		this.loading_settings = loading_settings;
		this.metaTag = metaTag;
		this.is_demo = is_demo;
	}

	public static fromJSON(json: any): Event {
		let event: Event = new Event();
		Object.assign(event, json);
		if (json.start) {
			const start = moment(json.start);
			event.startDate = start.format('YYYY-MM-DD');
			event.startHour = start.format('HH:mm:ss');
		}
		if (json.end) {
			const end = moment(json.end);
			event.endDate = end.format('YYYY-MM-DD');
			event.endHour = end.format('HH:mm:ss');
		}

		let tempZones: Array<TemplateZone> = [];
		if (json.zones) {
			json.zones.forEach((zone: Object) => {
				tempZones.push(TemplateZone.fromJSON(zone));
			});
		}

		let _services: Array<Service> = [];
		if (json.services) {
			json.services.forEach((service: Object) => {
				_services.push(Service.fromJSON(service));
			});
		}

		let _rooms: Array<EventRoom> = [];
		if (json.rooms) {
			json.rooms.forEach((room: Object) => {
				_rooms.push(EventRoom.fromJSON(room));
			});
		}

		let _fonts: Array<Font> = [];
		if (json.fonts) {
			json.fonts.forEach((font: Object) => {
				_fonts.push(Font.fromJSON(font));
			})
		}

		let _plans: Array<PaymentPlan> = [];
		if (json.payment_plans) {
			json.payment_plans.forEach((plan: Object) => {
				_plans.push(PaymentPlan.fromJSON(plan));
			})
		}

		if (json.meta_tag) {
			event.$metaTag = MetaTag.fromJSON(json.meta_tag);
		}

		if (json.organisation) {
			event.$organisation = Organisation.fromJSON(json.organisation)
		}

		event.$payment_plans = _plans;
		event.$rooms = _rooms;
		event.services = _services;
		event.$fonts = _fonts;
		event.$template = EventTemplate.fromJSON(json.template);
		if (json.template) {
			event.$event_template_id = json.template.id
			if (json.event_template_settings && json.event_template_settings.length > 0)
				event.$templateSettings = JSON.parse(json.event_template_settings);
		}

		if (json.registration_layout_settings) {
			event.$registration_layout_settings = JSON.parse(json.registration_layout_settings)
		}

		if (json.custom_domains)
			event.$custom_domains

		// if (json.translation_data && json.translation_data.languages) {
		// 	if (!event.translation_data) {
		// 		event.translation_data = {}
		// 	}
		// 	if (!event.translation_data.languages) {
		// 		event.translation_data.languages = [];
		// 	}
		// 	for (let langJson of json.translation_data.languages) {
		// 		const lang = Language.fromJSON(langJson);
		// 		event.translation_data.languages.push(lang);
		// 		// event.$languages = Object.assign({}, json.translation_data);	
		// 	}
		// }

		event.$zones = tempZones;

		return event;
	}

	public get $organisation(): Organisation | null { return this.organisation; }
	public set $organisation(value: Organisation | null) { this.organisation = value; }

	public get $custom_domains(): Array<any> | null { return this.custom_domains; }
	public set $custom_domains(value: Array<any> | null) { this.custom_domains = value; }

	public get $payment_plans(): Array<PaymentPlan> | null {
		return this.payment_plans;
	}
	public set $payment_plans(value: Array<PaymentPlan> | null) {
		this.payment_plans = value;
	}

	public get $TZ(): string | null {
		return this.TZ;
	}
	public set $TZ(value: string | null) {
		this.TZ = value;
	}

	public get $start(): string | null {
		return this.start;
	}
	public get $end(): string | null {
		if (this.endDate == null || this.endDate.length == 0) return null;
		if (this.endHour == null || this.endHour.toString().length == 0) return null;

		return this.end;
	}
	public get $zones(): Array<TemplateZone> {
		return this.zones;
	}

	public get $title(): string | null {
		return this.title;
	}

	public get $internal_title(): string | null {
		return this.internal_title;
	}

	public get $loginPageScripts(): string | null {
		return this.loginPageScripts;
	}

	public get $eventPageScripts(): string | null {
		return this.eventPageScripts;
	}

	public get $css(): string | null {
		return this.css;
	}

	/**
	 * Getter $slug
	 * @return {string}
	 */
	public get $slug(): string | null {
		return this.slug;
	}

	/**
	 * Getter $id
	 * @return {number }
	 */
	public get $id(): number | null {
		return this.id;
	}

	/**
	 * Getter $organisation_id
	 * @return {number }
	 */
	public get $organisation_id(): number | null {
		return this.organisation_id;
	}

	/**
	 * Getter $logo
	 * @return {string }
	 */
	public get $logo(): string | null {
		return this.logo;
	}

	/**
	 * Getter $background
	 * @return {string }
	 */
	public get $background(): string | null {
		return this.background;
	}

	/**
	 * Getter $background_type
	 * @return {string }
	 */
	public get $background_type(): string | null {
		return this.background_type;
	}

	/**
	 * Getter $color
	 * @return {string }
	 */
	public get $color(): string | null {
		return this.color;
	}

	/**
	 * Getter $favicon
	 * @return {string }
	 */
	public get $favicon(): string | null {
		return this.favicon;
	}

	/**
	 * Getter $event_gdpr
	 * @return {boolean}
	 */
	public get $event_gdpr(): boolean {
		return this.event_gdpr;
	}

	/**
	 * Getter $event_gdpr_link
	 * @return {string }
	 */
	public get $event_gdpr_link(): string | null {
		return this.event_gdpr_link;
	}

	/**
	 * Getter $security_type
	 * @return {string }
	 */
	public get $security_type(): string {
		return this.security_type;
	}

	public get $event_template_id(): number {
		return this.event_template_id;
	}

	/**
	 * Getter $security_data
	 * @return {string}
	 */
	public get $security_data(): string | null {
		return this.security_data;
	}

	/**
	 * Getter $estimted_viewr
	 * @return {number}
	 */
	public get $estimatedViewers(): number {
		return this.estimated_viewers;
	}

	/**
	 * Getter $startDate
	 * @return {string}
	 */
	public get $startDate(): string {
		return this.startDate;
	}

	/**
	 * Getter $end
	 * @return {string}
	 */
	public get $endDate(): string {
		return this.endDate;
	}
	public get $backgroundType(): string | null {
		return this.background_type;
	}
	/**
	 * Getter $start
	 * @return {string}
	 */
	public get $startHour(): string {
		return this.startHour;
	}

	public get $translation_data(): any {
		return this.translation_data;
	}

	public get $services(): Array<Service> | null {
		return this.services;
	}
	public get $rooms(): Array<EventRoom> | null {
		return this.rooms;
	}
	/**
	 * Getter $end
	 * @return {string}
	 */
	public get $endHour(): string {
		return this.endHour;
	}
	public get $languages(): Language[] {
		const result: Language[] = [];
		if (!this.translation_data.languages) {
			return result;
		}

		for (let l of this.translation_data.languages) {
			result.push(Language.fromJSON(l));
		}
		return result;
	}
	public get $default_language(): Language | undefined {
		const langs = this.$languages;
		for (let l of langs) {
			if (l.$is_default) {
				return l;
			}
		}
		return;
	}
	public get $additional_languages(): Language[] {
		const result = [];
		const langs = this.$languages;
		for (let l of langs) {
			if (!l.$is_default) {
				result.push(l);
			}
		}
		return result;
	}

	public get $states(): any[] | null {
		return this.states;
	}

	public get $currentStateId(): number | null {
		if (!this.states) {
			return null
		}
		if (!this.currentStateId) {
			for (const eventState of this.states) {
				if (eventState.is_active)
					this.currentStateId = eventState.id;
			}
		}
		return this.currentStateId;
	}

	public get $show_title(): boolean | null {
		return this.show_title;
	}
	public get $event_url(): string | null { return this.event_url; }


	public get $fonts(): Array<Font> | null {
		return this.fonts;
	}
	public get $registration_layout_settings(): any | null {
		return this.registration_layout_settings;
	}

	public get $template(): EventTemplate | null {
		return this.template;
	}
	public get $templateSettings(): any | null {
		return this.event_template_settings;
	}
	public get $user_roles(): Array<string> | null { return this.user_roles; }
	public get $metaTag(): MetaTag | null { return this.metaTag; }

	public get $loading_settings(): string {
		return this.loading_settings;
	}

	public get $is_demo(): boolean { return this.is_demo; }

	public get $fullTitle(): string {
		let _title = this.title!;

		if (this.internal_title) {
			_title += ' (' + this.internal_title + ')';
		}

		return _title;
	}




	public set $currentStateId(value: number | null) {
		this.currentStateId = value;
	}


	public set $languages(value: Language[]) {
		this.translation_data.languages = value;
	}
	public set $states(value: any[] | any) {
		this.states = value;
	}
	public set $metaTag(value: MetaTag | null) { this.metaTag = value; }

	/**
	 * Setter $id
	 * @param {number } value
	 */
	public set $id(value: number | null) {
		this.id = value;
	}
	public set $backgroundType(value: string | null) {
		this.background_type = value;
	}
	/**
	 * Setter $title
	 * @param {string } value
	 */
	public set $title(value: string | null) {
		this.title = value;
	}

	/**
	 * Setter $internal_title
	 * @param {string } value
	 */
	public set $internal_title(value: string | null) {
		this.internal_title = value;
	}

	/**
	 * Setter $slug
	 * @param {string } value
	 */
	public set $slug(value: string | null) {
		this.slug = value;
	}

	/**
	 * Setter $organisation_id
	 * @param {number } value
	 */
	public set $organisation_id(value: number | null) {
		this.organisation_id = value;
	}

	/**
	 * Setter $logo
	 * @param {string } value
	 */
	public set $logo(value: string | null) {
		this.logo = value;
	}

	/**
	 * Setter $background
	 * @param {string } value
	 */
	public set $background(value: string | null) {
		this.background = value;
	}

	/**
	 * Setter $background_type
	 * @param {string } value
	 */
	public set $background_type(value: string | null) {
		this.background_type = value;
	}

	public set $loginPageScripts(value: string | null) {
		this.loginPageScripts = value;
	}

	public set $eventPageScripts(value: string | null) {
		this.eventPageScripts = value;
	}

	public set $css(value: string | null) {
		this.css = value;
	}

	/**
	 * Setter $color
	 * @param {string } value
	 */
	public set $color(value: string | null) {
		this.color = value;
	}

	/**
	 * Setter $favicon
	 * @param {string } value
	 */
	public set $favicon(value: string | null) {
		this.favicon = value;
	}

	/**
	 * Setter $event_gdpr
	 * @param {boolean} value
	 */
	public set $event_gdpr(value: boolean) {
		this.event_gdpr = value;
	}

	/**
	 * Setter $event_gdpr_link
	 * @param {string } value
	 */
	public set $event_gdpr_link(value: string | null) {
		this.event_gdpr_link = value;
	}

	/**
	 * Setter $security_type
	 * @param {string } value
	 */
	public set $security_type(value: string) {
		this.security_type = value;
	}

	/**
	 * Setter $security_data
	 * @param {string} value
	 */
	public set $security_data(value: string | null) {
		this.security_data = value;
	}

	public set $event_template_id(value: number) {
		this.event_template_id = value;
	}

	public set $zones(value: Array<TemplateZone>) {
		this.zones = value;
	}

	/**
	 * Getter $estimted_viewr
	 * @return {number}
	 */
	public set $estimatedViewers(estimatedViewer: number) {
		this.estimated_viewers = estimatedViewer;
	}

	/**
	 * Getter $start
	 * @return {string}
	 */
	public set $startDate(start: string) {
		this.startDate = start;
		this.start = this.startDate + ' ' + this.startHour;
	}

	/**
	 * Getter $end
	 * @return {string}
	 */
	public set $endDate(end: string) {
		this.endDate = end;
		if (end != null)
			this.end = this.endDate + ' ' + this.endHour;
		else
			this.end = null;
	}

	/**
	 * Getter $startHour
	 * @return {Moment}
	 */
	public set $startHour(startHour: string) {
		this.startHour = startHour;
		this.start = this.startDate + ' ' + this.startHour;
	}

	/**
	 * Getter $endHour
	 * @return {Moment}
	 */
	public set $endHour(endHour: string) {
		this.endHour = endHour;
		if (endHour != null)
			this.end = this.endDate + ' ' + this.endHour;
		else
			this.end = null;
	}
	public set $show_title(value: boolean | null) {
		this.show_title = value;
	}
	public set $event_url(value: string | null) {
		this.event_url = value;
	}
	public set $services(value: Array<Service> | null) {
		this.services = value;
	}
	public set $rooms(value: Array<EventRoom> | null) {
		this.rooms = value;
	}
	public set $fonts(value: Array<Font> | null) {
		this.fonts = value;
	}
	public set $registration_layout_settings(value: any | null) {
		this.registration_layout_settings = value;
	}
	public set $template(value: EventTemplate | null) { this.template = value; }
	public set $templateSettings(value: any | null) { this.event_template_settings = value }

	public set $user_roles(value: Array<string> | null) { this.user_roles = value; }


	public hasUserRole(value: string, recursive: boolean = true) {
		if (!this.user_roles) return false;

		if (recursive) {
			return this.user_roles.includes(value);
		} else {
			return this.user_roles[0] == value;
		}
	}

	public get $archive_on(): string | null {
		return this.archive_on;
	}

	public set $archive_on(value: string | null) {
		this.archive_on = value;
	}

	public hasService(serviceName: string) {
		if (!this.services) return false;
		let result = false;
		for (let service of this.services) {
			if (service.$name.toLowerCase() == serviceName.toLowerCase()) {
				result = true;
			}
		}
		return result;
	}

	public set $loading_settings(value: string) {
		this.loading_settings = value;
	}

	public set $is_demo(value: boolean) {
		this.is_demo = value;
	}
}
