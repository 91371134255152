import React, { createContext, useState, FC, useRef, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./style/snackbar.scss";
import { Alert } from "reactstrap";

// const reactstrap = require('reactstrap');
// const Alert = reactstrap;

//import { faPhone, faPhoneSlash } from '@fortawesome/free-solid-svg-icons';
//import './styles/NTSnackbar.scss';

export const defaultPosition = 'top-right';
export const defaultDuration = 5000;
export const defaultInterval = 250;
export const positions = [
	'top-left',
	'top-center',
	'top-right',
	'bottom-left',
	'bottom-center',
	'bottom-right',
];

export const SnackbarContext = createContext({});


interface SnackbarProps {
	children: any;
}

const Snackbar: FC<SnackbarProps> = ({ children }) => {
	// Current open state
	const [open, setOpen] = useState(false)
	// Snackbar's text
	const [text, setText] = useState('')
	// Snackbar's duration
	const [duration, setDuration] = useState(defaultDuration)
	// Snackbar color
	const [color, setColor] = useState("success");
	// Snackbar's position
	//const [position, setPosition] = useState(defaultPosition)
	// Custom styles for the snackbar itself
	//const [customStyles, setCustomStyles] = useState({})
	// Custom styles for the close button
	//const [closeCustomStyles, setCloseCustomStyles] = useState({})

	const snackbarTimer = useRef(0);
	const openTimer = useRef(0);

	const triggerSnackbar = (text: string, duration: number) => {

		setText(text)
		void duration;
		setDuration(5000)
		//setPosition(position)
		//setCustomStyles(style)
		//setCloseCustomStyles(closeStyle)

		setOpen(true)
	}

	// Manages all the snackbar's opening process
	const openSnackbar = (text: string, duration: number, color: string = "success") => {
		// Closes the snackbar if it is already open
		setColor(color);
		if (open === true) {
			setOpen(false)
			clearTimeout(snackbarTimer.current);
			snackbarTimer.current = window.setTimeout(() => {
				triggerSnackbar(text, duration);
			}, defaultInterval)
		} else {
			triggerSnackbar(text, duration);
		}
	}

	// Closes the snackbar just by setting the "open" state to false
	const closeSnackbar = () => {
		setOpen(false)
	}

	useEffect(() => {
		return (() => {
			clearTimeout(snackbarTimer.current);
			clearTimeout(openTimer);
		});
	}, [])

	return (
		<SnackbarContext.Provider value={{ openSnackbar: openSnackbar, closeSnackbar: closeSnackbar }}>
			{children}

			<CSSTransition
				in={open}
				timeout={150}
				appear={false}
				mountOnEnter
				unmountOnExit
				onEnter={() => {
					clearTimeout(openTimer.current)
					openTimer.current = window.setTimeout(() => setOpen(false), duration);
				}}
				classNames={'slide'}
			>
				<Alert className="snackbar" color={color} style={{ zIndex: "1000" }}>
					<div className="d-flex align-items-center align-content-center">
						<span className="font-size-lg d-block btn-icon d-40 mr-3 text-center bg-white-50 rounded-sm">
							<FontAwesomeIcon icon={['far', 'keyboard']} />
						</span>
						<span>
							<strong className="d-block">{text}</strong>
						</span>
					</div>
				</Alert>
			</CSSTransition>
		</SnackbarContext.Provider>
	)
}

export default Snackbar;