import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Context as UserContext } from '../context/UserContext';
import AuthService from "../Services/AuthService";

const ProtectedRoute = ({ children }) => {
	const { userState, userDispatch } = useContext(UserContext);
	const navigate = useNavigate();


	useEffect(() => {
		if (!AuthService.isLoggedIn()) {
			// user is not authenticated
			navigate('', {replace: true})
		}
	
		fetchUser();
	},[])

	async function fetchUser() {
		if (userState.user.id != 0) return;
		const user = await AuthService.getMe();

		userDispatch({
			type: 'SET_USER',
			user: user,
		});
	}

	return children;
};

export default ProtectedRoute;