import React from "react";
import * as Sentry from "@sentry/react";
import PageError500 from "../../pages/PageError500";

export default class StandardErrorBoundary extends React.Component {
	constructor(props) {
		super(props);

		Sentry.init({
			dsn: process.env.REACT_APP_SENTRY_DSN,
			maxBreadcrumbs: 50,
			environment: process.env.REACT_APP_ENV,
			debug: true,
		});

		// to keep track of when an error occurs
		// and the error itself
		this.state = {
			hasError: false,
			error: undefined
		};
	}

	// update the component state when an error occurs
	static getDerivedStateFromError(error) {
		// specify that the error boundary has caught an error
		return {
			hasError: true,
			error: error
		};
	}

	// defines what to do when an error gets caught
	componentDidCatch(error, errorInfo) {
		// log the error
		try {
			console.error(error)
			Sentry.captureException(error);
		} catch (e) {
			console.error("Unable to report error to sentry...");
			console.error(e)
		}

		// record the error in an APM tool...
	}

	render() {
		// if an error occurred
		if (this.state.hasError) {
			return (
				<PageError500 />
			);
		} else {
			// default behavior
			return this.props.children;
		}
	}
}