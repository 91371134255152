import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
import Router from './Router';
import { ToastContainer, toast } from 'react-toastify';
import './assets/base.scss';
import SnackbarProvider from './HOC/Snackbar';
import { library } from '@fortawesome/fontawesome-svg-core';
import { default as Socket } from './HOC/Socket/Socket'
import { default as AccessRights } from './HOC/AccessRights/AccessRights'
import { Provider as UserProvider } from './context/UserContext';
import 'react-toastify/dist/ReactToastify.css';

import {
	fab,
	faFacebook,
	faTwitter,
	faVuejs,
	faReact,
	faHtml5,
	faGoogle,
	faInstagram,
	faPinterest,
	faYoutube,
	faDiscord,
	faSlack,
	faDribbble,
	faGithub
} from '@fortawesome/free-brands-svg-icons';
import {
	far,
	faSquare,
	faLifeRing,
	faCheckCircle,
	faTimesCircle,
	faDotCircle,
	faThumbsUp,
	faComments,
	faFolderOpen,
	faTrashAlt,
	faFileImage,
	faFileArchive,
	faCommentDots,
	faFolder,
	faKeyboard,
	faCalendarAlt,
	faEnvelope,
	faAddressCard,
	faMap,
	faObjectGroup,
	faImages,
	faUser,
	faLightbulb,
	faGem,
	faClock,
	faUserCircle,
	faQuestionCircle,
	faBuilding,
	faBell,
	faFileExcel,
	faFileAudio,
	faFileVideo,
	faFileWord,
	faFilePdf,
	faFileCode,
	faFileAlt,
	faEye,
	faChartBar
} from '@fortawesome/free-regular-svg-icons';
import {
	fas,
	faExclamation,
	faAngleDoubleRight,
	faAngleDoubleLeft,
	faCheck,
	faSmile,
	faHeart,
	faBatteryEmpty,
	faBatteryFull,
	faChevronRight,
	faSitemap,
	faPrint,
	faMapMarkedAlt,
	faTachometerAlt,
	faAlignCenter,
	faExternalLinkAlt,
	faShareSquare,
	faInfoCircle,
	faSync,
	faQuoteRight,
	faStarHalfAlt,
	faShapes,
	faCarBattery,
	faTable,
	faCubes,
	faPager,
	faCameraRetro,
	faBomb,
	faNetworkWired,
	faBusAlt,
	faBirthdayCake,
	faEyeDropper,
	faUnlockAlt,
	faDownload,
	faAward,
	faPlayCircle,
	faReply,
	faUpload,
	faBars,
	faEllipsisV,
	faSave,
	faSlidersH,
	faCaretRight,
	faChevronUp,
	faPlus,
	faLemon,
	faChevronLeft,
	faTimes,
	faChevronDown,
	faFilm,
	faSearch,
	faEllipsisH,
	faCog,
	faArrowsAltH,
	faPlusCircle,
	faAngleRight,
	faAngleUp,
	faAngleLeft,
	faAngleDown,
	faArrowUp,
	faArrowDown,
	faArrowRight,
	faArrowLeft,
	faStar,
	faSignOutAlt,
	faLink
} from '@fortawesome/free-solid-svg-icons';
import Notifications from './components/Notifications';
import StandardErrorBoundary from './HOC/StandardErrorBoundary';
library.add(
	far,
	faSquare,
	faLifeRing,
	faCheckCircle,
	faTimesCircle,
	faDotCircle,
	faThumbsUp,
	faComments,
	faFolderOpen,
	faTrashAlt,
	faFileImage,
	faFileArchive,
	faCommentDots,
	faFolder,
	faKeyboard,
	faCalendarAlt,
	faEnvelope,
	faAddressCard,
	faMap,
	faObjectGroup,
	faImages,
	faUser,
	faLightbulb,
	faGem,
	faClock,
	faUserCircle,
	faQuestionCircle,
	faBuilding,
	faBell,
	faFileExcel,
	faFileAudio,
	faFileVideo,
	faFileWord,
	faFilePdf,
	faFileCode,
	faFileAlt,
	faEye,
	faChartBar
);
library.add(
	fab,
	faFacebook,
	faTwitter,
	faVuejs,
	faReact,
	faHtml5,
	faGoogle,
	faInstagram,
	faPinterest,
	faYoutube,
	faDiscord,
	faSlack,
	faDribbble,
	faGithub
);
library.add(
	fas,
	faExclamation,
	faAngleDoubleRight,
	faAngleDoubleLeft,
	faCheck,
	faSmile,
	faHeart,
	faBatteryEmpty,
	faBatteryFull,
	faChevronRight,
	faSitemap,
	faPrint,
	faMapMarkedAlt,
	faTachometerAlt,
	faAlignCenter,
	faExternalLinkAlt,
	faShareSquare,
	faInfoCircle,
	faSync,
	faQuoteRight,
	faStarHalfAlt,
	faShapes,
	faCarBattery,
	faTable,
	faCubes,
	faPager,
	faCameraRetro,
	faBomb,
	faNetworkWired,
	faBusAlt,
	faBirthdayCake,
	faEyeDropper,
	faUnlockAlt,
	faDownload,
	faAward,
	faPlayCircle,
	faReply,
	faUpload,
	faBars,
	faEllipsisV,
	faSave,
	faSlidersH,
	faCaretRight,
	faChevronUp,
	faPlus,
	faLemon,
	faChevronLeft,
	faTimes,
	faChevronDown,
	faFilm,
	faSearch,
	faEllipsisH,
	faCog,
	faArrowsAltH,
	faPlusCircle,
	faAngleRight,
	faAngleUp,
	faAngleLeft,
	faAngleDown,
	faArrowUp,
	faArrowDown,
	faArrowRight,
	faArrowLeft,
	faStar,
	faSignOutAlt,
	faLink
);
const store = configureStore();



class App extends Component {
	render() {
		return (
			<Provider store={store}>
				<StandardErrorBoundary>
					<UserProvider>
						<SnackbarProvider>
							<BrowserRouter>
								<Socket>
									<AccessRights>
										<Router />
										<Notifications />
										<ToastContainer
											enableMultiContainer
											containerId={'A'}
											position={toast.POSITION.BOTTOM_LEFT}
										/>
										<ToastContainer
											enableMultiContainer
											containerId={'B'}
											position={toast.POSITION.TOP_RIGHT}
										/>
										<ToastContainer
											enableMultiContainer
											containerId={'C'}
											position={toast.POSITION.BOTTOM_CENTER}
										/>
									</AccessRights>
								</Socket>
							</BrowserRouter>
						</SnackbarProvider>
					</UserProvider>
				</StandardErrorBoundary>
			</Provider>
		);
	}
}

export default App;
