import PaymentPlan from "./PaymentPlan";

export const UserRoles: string[] = [
	'Admin',
	'Project Manager',
	'Manager',
	'Moderator',
	'Presenter',
	'Participant'
]
export default class User {
	private id: number | null;
	private first_name: string;
	private last_name: string;
	private email: string | null;
	private roles: string[] | null;
	private avatar: string | null;
	private role_name: string | null;
	private anonymous: boolean;
	private notifications: boolean;
	private receive_emails: boolean;
	private address: Object;
	private access_rights: any[] | null;
	private payment_plans: PaymentPlan[] | null;
	private created_at: Date;

	constructor(
		id: number | null = 0,
		first_name: string = "",
		last_name: string = "",
		email: string = "",
		roles: string[] = [],
		avatar: string | null = null,
		role_name: string | null = null,
		anonymous: boolean = false,
		notifications: boolean = true,
		receive_emails: boolean = true,
		address: Object = {},
		access_rights: any[] | null = null,
		payment_plans: PaymentPlan[] | null = null,
		created_at: Date = new Date(),
	) {
		this.id = id;
		this.first_name = first_name;
		this.last_name = last_name;
		this.email = email;
		this.roles = roles;
		this.avatar = avatar;
		this.role_name = role_name;
		this.anonymous = anonymous;
		this.notifications = notifications;
		this.receive_emails = receive_emails;
		this.address = address;
		this.access_rights = access_rights;
		this.payment_plans = payment_plans;
		this.created_at = created_at;
	}

	/**
	 * Create a user object from json
	 * @param json The json representation of a user
	 * @return {User} A user object based on the information in the provided json
	 */
	public static fromJSON(json: any): User {
		let user: User = new User();
		Object.assign(user, json);

		if (json && json.hasOwnProperty('address'))
			user.address = json.address;

		if (json && json.payment_plans) {
			user.$paymentPlans = [];
			for (let p of json.payment_plans) {
				user.$paymentPlans.push(PaymentPlan.fromJSON(p));
			}
		}

		if (json?.created_at) {
			user.$created_at = new Date(json.created_at);
		}

		return user;
	}

	/**
	 * GETTERS
	 */

	public get $id(): number | null {
		return this.id
	}

	public get $first_name(): string {
		return this.first_name;
	}

	public get $last_name(): string {
		return this.last_name;
	}

	public get $name(): string {
		let name = this.first_name;
		if (this.first_name && this.last_name) {
			name += ' ' + this.last_name;
		} else {
			name = this.last_name;
		}
		return name;
	}

	public get $email(): string | null {
		return this.email;
	}

	public get $role(): string | null {
		if (this.roles)
			return this.roles[0];
		return null;
	}
	public get $avatar(): string | null {
		return this.avatar;
	}
	public get $role_name(): string | null {
		return this.role_name;
	}

	public get $anonymous(): boolean {
		return this.anonymous;
	}

	public get $notifications(): boolean {
		return this.notifications;
	}

	public get $receive_emails(): boolean {
		return this.receive_emails;
	}

	public get $address(): Object {
		return this.address;
	}

	public get $accessRights(): any[] | null {
		return this.access_rights;
	}

	public get $paymentPlans(): PaymentPlan[] | null {
		return this.payment_plans;
	}

	public get $created_at(): Date {
		return this.created_at;
	}

	/**
	 * SETTERS
	 */

	public set $first_name(value: string) {
		this.first_name = value;
	}
	public set $last_name(value: string) {
		this.last_name = value;
	}
	public set $email(value: string | null) {
		this.email = value;
	}
	public set $anonymous(value: boolean) {
		this.anonymous = value;
	}
	public set $notifications(value: boolean) {
		this.notifications = value;
	}
	public set $receive_emails(value: boolean) {
		this.receive_emails = value;
	}
	public set $address(value: Object) {
		this.address = value;
	}
	public set $paymentPlans(value: Array<PaymentPlan> | null) {
		this.payment_plans = value;
	}
	public set $accessRights(value: any[] | null) {
		this.access_rights = value;
	}
	public set $created_at(value: Date) {
		this.created_at = value;
	}
	/**
	 * Checks if the user has a particular role 
	 * @param role The role to check 
	 * @param recursiveSearch turn false in order to check for literal user permissions
	 * @returns 
	 */
	public hasRole(role: string, recursiveSearch: boolean = true): boolean {
		if (!this.roles || this.roles.length == 0) {
			return false;
		}
		if (recursiveSearch) {
			return this.roles.includes(role);
		} else {
			return this.roles[0] == role;
		}

	}
}