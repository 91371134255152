export default class BlockTemplate {
	private id: number | null
	private name: String
	private path: String
	private isDefault: boolean | null

	constructor(
		id: number | null = 0,
		name: String = "",
		path: String = "",
		isDefault: boolean = false
	) {
		this.id = id
		this.name = name
		this.path = path
		this.isDefault = isDefault
	}

	/**
	 * Create a BlockTemplate object from json
	 * @param json The json representation of a user
	 * @return {BlockTemplate} A new BlockTemplate object based on the information in the provided json
	 */
	public static fromJSON(json: any): BlockTemplate {
		var blockTemplate: BlockTemplate = new BlockTemplate()
		Object.assign(blockTemplate, json)
		return blockTemplate
	}

	public get $name(): String {
		return this.name
	}

	public get $path(): String {
		return this.path
	}

	public get $isDefault(): Boolean | null {
		return this.isDefault
	}

	public get $id(): number | null {
		return this.id
	}
}
