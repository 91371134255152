import { v4 as uuid } from 'uuid';

export default class Font {
	private key: string;
	private id: number | null;
	private font_family: string | null;
	private filename: string | null;
	private url: string | null;
	private font_weight: string | null;
	private font_style: string | null;
	private applied_to: Array<string> | null;

	constructor(
		id: number | null = null,
		font_family: string | null = null,
		filename: string | null = null,
		url: string | null = null,
		font_weight: string | null = null,
		font_style: string | null = null,
		applied_to: Array<string> | null = null,
	) {
		this.key = uuid();
		this.id = id;
		this.font_family = font_family;
		this.filename = filename;
		this.url = url;
		this.font_weight = font_weight;
		this.font_style = font_style;
		this.applied_to = applied_to;
	}

	public static fromJSON(json: any): Font {
		const f = new Font();
		Object.assign(f, json);

		f.$applied_to = JSON.parse(json.applied_to);

		return f;
	}

	public get $key(): string { return this.key; }

	public get $id(): number | null {
		return this.id;
	}
	public get $font_family(): string | null {
		return this.font_family;
	}
	public get $filename(): string | null {
		return this.filename;
	}
	public get $url(): string | null {
		return this.url;
	}
	public get $font_weight(): string | null {
		return this.font_weight;
	}
	public get $font_style(): string | null {
		return this.font_style;
	}
	public get $applied_to(): Array<string> | null {
		return this.applied_to;
	}
	public get $file_extension(): string | null {
		const regex = /(?:\.([^.]+))?$/;
		if (!this.filename) {
			return null;
		}
		const result = regex.exec(this.filename)
		if (result) {
			return result[1];
		}

		return null;
	}


	public set $id(value: number | null) {
		this.id = value;
	}
	public set $font_family(value: string | null) {
		this.font_family = value;
	}
	public set $filename(value: string | null) {
		this.filename = value;
	}
	public set $url(value: string | null) {
		this.url = value;
	}
	public set $font_weight(value: string | null) {
		this.font_weight = value;
	}
	public set $font_style(value: string | null) {
		this.font_style = value;
	}
	public set $applied_to(value: Array<string> | null) {
		this.applied_to = value;
	}

}