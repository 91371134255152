import React from 'react';
import clsx from 'clsx';

import { connect } from 'react-redux';

import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';

import {
	SidebarHeader,
	SidebarMenu,
	SidebarFooter
} from '../../layout-components';

const Sidebar = (props) => {
	const toggleSidebarMobile = () => {
		setSidebarToggleMobile(!sidebarToggleMobile);
	};

	const {
		sidebarStyle,
		sidebarShadow,
		sidebarFooter,
		sidebarToggleMobile,
		setSidebarToggleMobile
	} = props;
	return (
		<>
			<style>{`
				.app-sidebar-fixed {
					height: 100vh !important;
				}
			`}
			</style>
			<div
				className={clsx('app-sidebar', sidebarStyle, {
					'app-sidebar--shadow': sidebarShadow
				})}
			>
				<SidebarHeader />
				
				<div className="app-sidebar--content">
					<SidebarMenu />
				</div>

				{sidebarFooter && <SidebarFooter />}
			</div>
			<div
				onClick={toggleSidebarMobile}
				className={clsx('app-sidebar-overlay', {
					'is-active': sidebarToggleMobile
				})}
			/>
		</>
	);
};

const mapStateToProps = (state) => ({
	sidebarShadow: state.ThemeOptions.sidebarShadow,
	sidebarFooter: state.ThemeOptions.sidebarFooter,
	sidebarStyle: state.ThemeOptions.sidebarStyle,
	sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
	setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
