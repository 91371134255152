import React from 'react';
import illustration1 from '../../assets/images/illustrations/pack1/authentication.svg'
import {
	Row,
	Col,
	Button,
	UncontrolledTooltip,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function PageError403() {
    return (
        <>
            <div className="app-wrapper bg-white">
                <div className="app-main">
                    <div className="app-content p-0">
                        <div className="app-inner-content-layout--main">
                            <div className="flex-grow-1 w-100 d-flex align-items-center">
                                <div className="bg-composed-wrapper--content">
                                    <div className="hero-wrapper bg-composed-wrapper min-vh-100">
                                        <div className="flex-grow-1 w-100 d-flex align-items-center">
                                            <Col lg="6" md="9" className="px-4 px-lg-0 mx-auto text-center text-black">
                                                <img src={illustration1} className="w-50 mx-auto d-block my-5 img-fluid" alt="..." />

                                                <h3 className="font-size-xxl line-height-sm font-weight-light d-block px-3 mb-3 text-black-50">
                                                    You do not have the rights to access this page
                                                </h3>
                                                <p>
                                                    Please navigate back to an URL you have access to
                                                </p>
                                                
                                            </Col>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
