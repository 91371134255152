import axios from 'axios';
import User from '../Models/User'
import PaginationService from './PaginationService';
import jwt_decode from "jwt-decode";
const AuthService = {
	/**
	 * Do a get request that is authenticated.
	 * @param {string} path The path for the request tot the API
	 * @throws error if the http response was incorrect.
	 */
	get: async function (path: string, params = {}): Promise<any> {
		const response = await axios.get(
			process.env.REACT_APP_BASE_URL + `${path}`,
			{
				params: params,
				headers: { Authorization: this._getJWT() },
			}
		);
		const paginatedResponse = PaginationService._handlePagination(response);

		return paginatedResponse;
	},

	/**
	 * Do a get request that is authenticated.
	 * @param {string} path The path for the request tot the API
	 * @throws error if the http response was incorrect.
	 */
	getUrl: async function (path: string): Promise<any> {
		const response = await axios.get(path, {
			headers: { Authorization: this._getJWT() },
		});
		const paginatedResponse = PaginationService._handlePagination(response);

		return paginatedResponse;
	},

	/**
	 * Send an authenticated put request.
	 * @param {string} path The path for the request tot the API
	 * @param payload The payload for the put request.
	 * @throws error if the http response was not 200/201
	 */
	put: async function (path: string, payload: any): Promise<any> {
		const response = await axios.put(
			process.env.REACT_APP_BASE_URL + `${path}`,
			payload,
			{
				headers: {
					Authorization: await this._getJWT(),
				},
			}
		);

		return response;
	},

	/**
	 * Send an authenticated post request.
	 * @param {string} path The path for the request tot the API
	 * @param payload The payload for the put request.
	 * @throws error if the http response was not 200/201
	 */
	post: async function (path: string, payload: any, download: boolean = false): Promise<any> {
		let extraData = {};
		extraData['headers'] = {
			Authorization: await this._getJWT(),
		};
		if(download) {
			extraData['responseType'] = 'blob';
		}
		const response = await axios.post(
			process.env.REACT_APP_BASE_URL + `${path}`,
			payload,
			extraData
		);

		return response;
	},

	/**
	 * Send an authenticated patch request.
	 * @param {string} path The path for the request tot the API
	 * @param payload The payload for the put request.
	 * @throws error if the http response was not 200/201
	 */
	patch: async function (path: string, payload: any): Promise<any> {
		const response = await axios.patch(
			process.env.REACT_APP_BASE_URL + `${path}`,
			payload,
			{
				headers: {
					Authorization: await this._getJWT(),
				},
			}
		);

		return response;
	},

	/**
	 * Do a delete request that is authenticated.
	 * @param {string} path The path for the request tot the API
	 * @throws error if the http response was incorrect.
	 */
	delete: async function (path: string): Promise<any> {
		const response = await axios.delete(
			process.env.REACT_APP_BASE_URL + `${path}`,
			{
				headers: { Authorization: this._getJWT() },
			}
		);

		return response;
	},

	login: async function (email: string, password: string): Promise<void> {
		const payload = { email: email, password: password };
		const response = await axios.post(
			process.env.REACT_APP_BASE_URL + 'auth/login',
			payload
		);
		const token = response.data['token'];
		var decoded = jwt_decode(token);

		localStorage.setItem('_myAraToken', token);
		localStorage.setItem('_myAraTokenExp', decoded['exp']);

		return response.data['user'];
	},

	isLoggedIn: function (): boolean {
		var token = localStorage.getItem('_myAraToken');

		if (!token) {
			return false;
		}

		return true;
	},

	/**
	 * Get a valid JWT. If the jwt expired, then a new one will be fetched in the background.
	 */
	_getJWT: function (): string {
		var token = localStorage.getItem('_myAraToken');

		if (this._isValidJWT() && token) {
			return 'Bearer ' + token;
		} else {
			if(!window.location.href.includes('elevated')) {
				window.location.replace("/login");
			}
			return '';
		}
		// TODO: check for valid token (if not renew)
		return 'Bearer ' + token;


	},

	_isValidJWT: function (): boolean {
		const exp = parseInt(localStorage.getItem('_myAraTokenExp'));
		const now = Math.round(Date.now() / 1000)	// Epoch milisecs to secs

		if (now > exp) {
			localStorage.removeItem("_myAraTokenExp");
			localStorage.removeItem("_myAraToken")
			return false;
		}

		return true;
	},
	registerUser: async function (
		email: string,
		event: number,
		password: string,
		extra_data: Object
	): Promise<object> {
		const payload = {
			email: email,
			payload: extra_data,
			event: event,
			password: password
		};

		const response = await AuthService
			.post('event/login', payload)
			.catch(function (error) {
				throw new Error(error.response.data.code);
			});
		return response.data.data['user'];
	},
	getMe: async function (): Promise<User> {
		const response = await axios.get(
			process.env.REACT_APP_BASE_URL + 'user/me',
			{
				headers: { Authorization: this._getJWT() },
			}
		);

		return User.fromJSON(response.data.data);
	},
	forgotPassword: async function (email: string, location: string, invitation: string|null = null, elevatedId: number|null = null): Promise<void> {
		const payload = { email: email, location: location, invitation: invitation, elevatedId: elevatedId };
		try {
			const response = await axios.post(
				process.env.REACT_APP_BASE_URL + 'auth/forgotPassword',
				payload
			);

			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	},
	resetPassword: async function (passwordResetCode: string, password: string): Promise<void> {
		const payload = { password: password };
		try {
			const response = await axios.post(
				process.env.REACT_APP_BASE_URL + 'auth/resetPassword/' + passwordResetCode,
				payload
			);

			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	},
	versions: async function (): Promise<void> {
		const payload = {};
		try {
			const response = await axios.get(
				process.env.REACT_APP_BASE_URL + 'versions',
				payload
			);

			return response.data;
		} catch (error) {
			throw new Error(error.message);
		}
	},
};


export default AuthService;
