
const PaginationService = {
	_handlePagination: function(resp): any {
        const data = resp.data.data;
		if(!data) {
			return resp
		}
		if(typeof data != 'string' && 'current_page' in data) {
			data['first_page_url'] = this._replaceHostOfPaginationURL(data['first_page_url']);
            data['last_page_url'] = this._replaceHostOfPaginationURL(data['last_page_url']);
            data['next_page_url'] = this._replaceHostOfPaginationURL(data['next_page_url']);
            data['prev_page_url'] = this._replaceHostOfPaginationURL(data['prev_page_url']);

            for(const key in data.links) {
                data.links[key]['url'] = this._replaceHostOfPaginationURL(data.links[key]['url']);
            }
		}
        resp.data.data = data;
		return resp;
	},

    _replaceHostOfPaginationURL: function(url): string {
        try{
            const u = new URL(url)
            const hostPart = u.protocol + '//' + u.hostname + '/';
            const newUrl = url.replace(hostPart, process.env.REACT_APP_BASE_URL);
            return newUrl;
        } catch(err) {
            return url;
        }
        

    }

}
export default PaginationService;