export default class EventTemplate {
    private id: number | null;
    private name: string | null;
    private path: string | null;
    private extra_info: string | null;

    constructor(
        id: number | null = null,
        name: string | null = null,
        path: string | null = null,
        extraInfo: string | null = null
    ) {
        this.id = id;
        this.name = name;
        this.path =path;
        this.extra_info = extraInfo;
    }

    public get $id() : number | null { return this.id; }
    public get $name() : string | null { return this.name; }
    public get $path() : string | null { return this.path; }
    public get $extraInfo() : string | null { return this.extra_info; }

    public set $id(value: number | null) { this.id = value; }
    public set $name(value: string | null) { this.name = value; }
    public set $path(value: string | null) { this.path = value; }
    public set $extraInfo(value: string | null) { this.extra_info = value; }

    /**
   * Create a BlockTemplate object from json
   * @param json The json representation of a user
   * @return {EventTemplate} A new BlockTemplate object based on the information in the provided json
   */
  public static fromJSON(json: any): EventTemplate {
    var eventTemplate: EventTemplate = new EventTemplate()
    Object.assign(eventTemplate, json)
    return eventTemplate
  }
}